// settingsStore.js
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useUserSettingsStore = create(
    persist(
        (set) => ({
            theme: "light",
            showNewFeaturesModal: true,
            toggleTheme: () => set((state) => ({ theme: state.theme === "light" ? "dark" : "light" })),
            toggleShowNewFeaturesModal: () => set((state) => ({ showNewFeaturesModal: !state.showNewFeaturesModal })),

        }),
        { name: 'user-settings-store' }
    )
);

export default useUserSettingsStore;
