import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography,
    FormControl,
    Autocomplete,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CircularProgress from "@mui/material/CircularProgress";
import { formatOptions } from "../../static/formats";
import useCardService from "../../hooks/useCardService";

const NewDeckModal = ({ open, onClose, onCreate, loadingCreate }) => {
    const [formState, setFormState] = useState({
        name: "",
        format: "",
        commander: "",
        privacy: "public",
        existingDeckOption: "none",
        existingDeckText: "",
        url: "",
    });
    const [validName, setValidName] = useState(true);
    const [commanderResults, setCommanderResults] = useState([]);

    const { useFindCardsAlike } = useCardService();
    const { getCards, dataFindCardsAlike, loadingFindCardsAlike } =
        useFindCardsAlike();

    useEffect(() => {
        if (formState.commander !== "") {
            const timeoutId = setTimeout(() => {
                getCards({
                    variables: { cardName: formState.commander, count: 10 },
                });
                // console.log("dataFindCardsAlike: ", dataFindCardsAlike);

                if (dataFindCardsAlike) {
                    setCommanderResults(
                        dataFindCardsAlike.findCardsAlike.slice(0, 5)
                    );
                }
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [formState.commander, getCards, dataFindCardsAlike]);

    const handleInputChange = (field, value) => {
        // Ensure that inputValue is always a string
        const inputValue = typeof value === "string" ? value : value.name || "";

        setFormState((prevState) => ({
            ...prevState,
            [field]: inputValue,
        }));

        if (field === "existingDeckOption" && value === "paste") {
            setFormState((prevState) => ({
                ...prevState,
                existingDeckText: "",
            }));
        }
    };

    const handleCardClick = (selectedCommander) => {
        const commanderName = selectedCommander?.name || selectedCommander;
        setFormState((prevState) => ({
            ...prevState,
            commander: commanderName,
        }));
        setCommanderResults([]); // Clear the results when a card is selected
    };

    const handleCreate = () => {
        // Pass the form state to the parent component
        if (!formState.name) {
            setValidName(false);
            return;
        }

        onCreate(formState);
    };

    const showCommanderTextField =
        formState.format &&
        formatOptions.find((option) => option.value === formState.format)
            .requiresCommander;

    const uniqueCommanderResults = commanderResults.filter(
        (commander, index, self) =>
            index === self.findIndex((c) => c.name === commander.name)
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>New Deck</DialogTitle>
            <DialogContent>
                <form onSubmit={handleCreate}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={formState.name}
                        onChange={(e) =>
                            handleInputChange("name", e.target.value)
                        }
                        sx={{ mb: 2, mt: 2 }}
                        error={!validName}
                        helperText={!validName && "Name required"}
                        required
                        autoComplete="off"
                    />
                    <FormControl fullWidth>
                        <InputLabel id="format-label">Format</InputLabel>
                        <Select
                            labelId="format-label"
                            label="Format"
                            variant="outlined"
                            fullWidth
                            value={formState.format}
                            required
                            onChange={(e) =>
                                handleInputChange("format", e.target.value)
                            }
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getcontentanchorel: null,
                                PaperProps: {
                                    style: {
                                        maxHeight: 250,
                                    },
                                },
                            }}
                            sx={{ marginBottom: 2 }}
                        >
                            {formatOptions.map((option) => (
                                <MenuItem key={option.id} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {showCommanderTextField && (
                        <Autocomplete
                            loading={loadingFindCardsAlike}
                            options={uniqueCommanderResults}
                            getOptionLabel={(option) => option.name}
                            key={(option) => uuidv4()}
                            onChange={(e, value) => handleCardClick(value)}
                            inputValue={formState.commander}
                            onInputChange={(e, value) =>
                                handleInputChange("commander", value)
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Commander"
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                />
                            )}
                            autoComplete="off"
                        />
                    )}
                    <Typography variant="subtitle2">Privacy</Typography>
                    <RadioGroup
                        aria-label="privacy"
                        name="privacy"
                        value={formState.privacy}
                        onChange={(e) =>
                            handleInputChange("privacy", e.target.value)
                        }
                        row
                    >
                        <FormControlLabel
                            value="public"
                            control={<Radio />}
                            label="Public"
                        />
                        <FormControlLabel
                            value="private"
                            control={<Radio />}
                            label="Private"
                        />
                    </RadioGroup>
                    <Typography variant="subtitle2" sx={{ mt: 1 }}>
                        Existing Deck (optional)
                    </Typography>
                    <RadioGroup
                        aria-label="existingDeck"
                        name="existingDeck"
                        value={formState.existingDeck}
                        onChange={(e) =>
                            handleInputChange(
                                "existingDeckOption",
                                e.target.value
                            )
                        }
                        row
                    >
                        <FormControlLabel
                            value="none"
                            control={<Radio />}
                            label="None"
                        />
                        <FormControlLabel
                            value="paste"
                            control={<Radio />}
                            label="Paste"
                        />
                        <FormControlLabel
                            value="url"
                            control={<Radio />}
                            label="URL"
                        />
                    </RadioGroup>
                    {formState.existingDeckOption === "paste" && (
                        <TextField
                            label="Deck List"
                            placeholder={`Example:
1 Sol Ring
1 Plains
1 Apex Altisaur`}
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={formState.existingDeckText}
                            onChange={(e) =>
                                handleInputChange(
                                    "existingDeckText",
                                    e.target.value
                                )
                            }
                            sx={{ mb: 2, mt: 1 }}
                            autoComplete="off"
                        />
                    )}
                    {formState.existingDeckOption === "url" && (
                        <TextField
                            label="Coming Soon!"
                            // label="URL (Supports Moxfield & Archidekt)"
                            placeholder={`https://www.url.com/decks/12345`}
                            variant="outlined"
                            fullWidth
                            value={formState.url}
                            onChange={(e) =>
                                handleInputChange("url", e.target.value)
                            }
                            sx={{ mb: 2, mt: 2 }}
                            autoComplete="off"
                            disabled
                        />
                    )}
                </form>
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>
                {loadingCreate && (
                    <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                        Please wait while we validate your card information.
                    </Typography>
                )}
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleCreate}
                    color="primary"
                    variant="contained"
                    disabled={loadingCreate}
                >
                    {loadingCreate ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        "Create"
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewDeckModal;
