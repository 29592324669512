import React, { useState, useEffect } from "react";
import {
    Typography,
    useMediaQuery,
    Box,
    IconButton,
    Button,
} from "@mui/material";
import CustomModal from "../../components/CustomModal";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import useDeckService from "../../hooks/useDeckService";
import SnackbarMessage from "../../components/SnackbarMessage";

const ManageOwnershipModal = ({
    open,
    onClose,
    deckId,
    card,
    onDeckUpdate,
}) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [currOwnershipCount, setCurrOwnershipCount] = useState(
        card && card.ownershipCount
    );
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

    const { updateDeckCardOwnership } = useDeckService();

    useEffect(() => {
        // Update currOwnershipCount when card changes
        setCurrOwnershipCount(card ? card.ownershipCount || 0 : 0);
    }, [card]);

    const modalStyle = {
        width: isDesktop ? "20%" : "95%",
        height: "200px",
    };

    const decreaseOwnership = () => {
        // cant go less than 1
        setCurrOwnershipCount((curr) => (curr > 0 ? curr - 1 : 0));
    };

    const increaseOwnership = () => {
        setCurrOwnershipCount((curr) =>
            curr < card.count ? curr + 1 : card.count
        );
    };

    const handleOwnershipSave = async () => {
        // make request to update ownership of cards
        try {
            const updatedDeck = await updateDeckCardOwnership(
                deckId,
                card.uuid,
                currOwnershipCount
            );
            onDeckUpdate(updatedDeck);
            setShowSuccessSnackbar(true);
            onClose();
        } catch (err) {
            console.log("err: ", err);
            setShowErrorSnackbar(true);
        }
    };

    return (
        <>
            <CustomModal open={open} onClose={onClose} style={modalStyle}>
                <Box>
                    <Typography sx={{ textAlign: "center" }} variant="h5">
                        Manage Ownership
                    </Typography>
                    <Box
                        sx={{
                            m: 3,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box>
                            <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                            >
                                Quantity
                            </Typography>
                            <Typography>
                                You own {card && card.ownershipCount}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton onClick={decreaseOwnership}>
                                <RemoveIcon />
                            </IconButton>
                            <Typography sx={{ ml: 1, mr: 1 }}>
                                {currOwnershipCount}
                            </Typography>
                            <IconButton onClick={increaseOwnership}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            textAlign: "center",
                            marginTop: 2,
                            margin: "auto",
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{ width: "200px" }}
                            onClick={() => handleOwnershipSave()}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </CustomModal>
            <SnackbarMessage
                open={showErrorSnackbar || showSuccessSnackbar}
                onClose={() => {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
                message={
                    showErrorSnackbar
                        ? "Error updating card ownership"
                        : "Card ownership updated successfully"
                }
                severity={showErrorSnackbar ? "error" : "success"}
            />
        </>
    );
};

export default ManageOwnershipModal;
