import { gql } from "@apollo/client";

const userQueries = {
    GET_USER_INFO: gql`
        query GetUserInfo {
            getUserInfo {
                displayName
                email
                # profilePicture
            }
        }
    `,

    VALID_DISPLAYNAME: gql`
        query ValidDisplayName($displayName: String!) {
            validDisplayName(displayName: $displayName)
        }
    `,

    UPLOAD_PROFILE_PICTURE: gql`
        mutation UploadProfilePicture($profilePicture: String!) {
            uploadProfilePicture(profilePicture: $profilePicture)
        }
    `,

    GET_PROFILE_PICTURE: gql`
        query GetProfilePicture($displayName: String!) {
            getProfilePicture(displayName: $displayName)
        }
    `,

    FIND_USERS_ALIKE: gql`
        query FindUsersAlike($displayName: String!) {
            findUsersAlike(displayName: $displayName) {
                id
                displayName
                email
                profilePicture
            }
        }
    `,
};

export default userQueries;
