// Context Component that manages user authentication details and helps with expired user jwt

import React, { createContext, useContext, useState, useEffect } from "react";
import userQueries from "../graphql/UserQueries";
import { useQuery } from "@apollo/client";
import jwtDecode from "../util/jwtUtils";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authenticatedUser, setAuthenticatedUser] = useState(null);
    const [sessionExpired, setSessionExpired] = useState(false);
    const { loading, error, data } = useQuery(userQueries.GET_USER_INFO);
    const location = useLocation();

    useEffect(() => {

        const checkTokenExpiration = () => {
            const accessToken = Cookies.get("accessToken");
            if (accessToken) {
                const decodedToken = jwtDecode(accessToken);

                if (decodedToken.exp * 1000 < Date.now()) {
                    // Token is expired
                    Cookies.remove("accessToken");
                    setAuthenticatedUser(null);
                    setSessionExpired(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)
                }
            }
        };

        if (!loading && data) {
            setAuthenticatedUser(data.getUserInfo);
        }

        checkTokenExpiration();

        if (error) {
            console.log("Error occurred in user authentication, please log out and log back in.");
        }

    }, [loading, data, error, location.pathname, authenticatedUser]);

    const handleCloseAlert = () => {
        setSessionExpired(false);
        // Optionally, you can navigate the user to the login page here
    };

    const contextValue = {
        authenticatedUser,
        setAuthenticatedUser,
    };

    return (
        <div>
            <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
            <Snackbar
                open={sessionExpired}
                autoHideDuration={10000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity="warning">
                    Your session has expired. Please log in again.
                </Alert>
            </Snackbar>
        </div>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
