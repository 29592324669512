import React, { useState } from "react";
import {
    Typography,
    Box,
    Button,
    useMediaQuery,
    Drawer,
    Paper,
    Tabs,
    Tab,
    Divider,
    IconButton,
    InputBase,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { manaCost, replaceManaSymbols } from "../../util/colorsUtils";
import CardRulings from "./CardRulings";
import CardLegalities from "./CardLegalities";
import CardPurchase from "./CardPuchase";
import useDeckService from "../../hooks/useDeckService";
import SnackbarMessage from "../../components/SnackbarMessage";

const CardPreviewDrawer = ({
    open,
    onClose,
    deckId,
    card,
    addCard,
    onAddCardComplete,
    ownership,
}) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const drawerWidth = isDesktop ? 500 : "100%";
    const [activeTab, setActiveTab] = useState(0);
    const [cardQuantity, setCardQuantity] = useState(1);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

    const { addCardToDeck } = useDeckService();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleAddCard = async () => {
        try {
            const updatedDeckData = await addCardToDeck(
                deckId,
                card.uuid,
                cardQuantity
            );
            onAddCardComplete(updatedDeckData);
            setShowSuccessSnackbar(true);
        } catch {
            setShowErrorSnackbar(true);
        }
    };

    return (
        <>
            <Drawer anchor="right" open={open} onClose={onClose}>
                <Box
                    width={drawerWidth}
                    component="div"
                    sx={{ overflow: "auto" }}
                >
                    <Box
                        sx={{
                            mb: 2,
                            display: "flex",
                            justifyContent: "flex-end",
                            p: 2,
                        }}
                    >
                        <Button onClick={onClose} color="text">
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                        <img
                            src={card.normal}
                            alt={`Preview of ${card.name}`}
                            style={{ maxWidth: "60%" }}
                        />
                        <Typography variant="h5">{card.name}</Typography>
                        <Typography
                            variant="body1"
                            sx={{ fontStyle: "italic", mt: 1 }}
                        >
                            {card.typeLine}
                        </Typography>
                        {card.usd && (
                            <Typography variant="subtitle2">
                                ${card.usd}
                            </Typography>
                        )}
                    </Box>

                    {ownership && addCard && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 1,
                            }}
                        >
                            <Paper
                                component="form"
                                sx={{
                                    p: "2px 4px",
                                    display: "flex",
                                    alignItems: "center",
                                    width: 200,
                                    justifyContent: "center",
                                    border: "1px solid #9e9e9e",
                                }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1, alignSelf: "center" }}
                                    placeholder="Enter quantity..."
                                    inputProps={{
                                        "aria-label": "enter quantity",
                                        onChange: (e) =>
                                            setCardQuantity(e.target.value),
                                        value: cardQuantity,
                                        type: "number",
                                    }}
                                />
                                <Divider
                                    sx={{ height: 28, m: 0.5 }}
                                    orientation="vertical"
                                />
                                <IconButton
                                    color="primary"
                                    sx={{ p: "10px" }}
                                    aria-label="directions"
                                    onClick={() => handleAddCard()}
                                >
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Paper>
                        </Box>
                    )}

                    <Box sx={{ marginTop: 2, width: "100%" }}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                            variant="fullWidth"
                        >
                            <Tab label="Details" />
                            <Tab label="Rulings" />
                            <Tab label="Legalities" />
                            <Tab label="Purchase" />
                        </Tabs>
                        <Divider />

                        {/* Content for each tab */}
                        {activeTab === 0 && (
                            <Box sx={{ mt: 3, p: 2 }}>
                                <Box sx={{ display: "flex", mb: 2 }}>
                                    {manaCost(card.manaCost)}
                                </Box>
                                {replaceManaSymbols(card.oracleText)}
                                {card.flavorText !== null && (
                                    <Typography
                                        variant="body1"
                                        sx={{ fontStyle: "italic", mt: 1 }}
                                    >
                                        "{card.flavorText}"
                                    </Typography>
                                )}
                            </Box>
                        )}
                        {activeTab === 1 && (
                            <Box sx={{ mt: 3, p: 2 }}>
                                <CardRulings cardRulingsUrl={card.rulingsUri} />
                            </Box>
                        )}
                        {activeTab === 2 && (
                            <Box sx={{ mt: 3, p: 2 }}>
                                <CardLegalities card={card} />
                            </Box>
                        )}
                        {activeTab === 3 && (
                            <Box sx={{ mt: 3, p: 2 }}>
                                <CardPurchase card={card} />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Drawer>
            <SnackbarMessage
                open={showErrorSnackbar || showSuccessSnackbar}
                onClose={() => {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
                message={
                    showErrorSnackbar
                        ? "Error adding card"
                        : "Card added successfully"
                }
                severity={showErrorSnackbar ? "error" : "success"}
            />
        </>
    );
};

export default CardPreviewDrawer;
