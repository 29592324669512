import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

const CardRulings = ({ cardRulingsUrl }) => {
    const [rulings, setRulings] = useState([]);

    useEffect(() => {
        const fetchRulings = async () => {
            try {
                const response = await fetch(cardRulingsUrl);
                const data = await response.json();
                setRulings(data.data || []);
            } catch (error) {
                console.error("Error fetching rulings:", error);
            }
        };

        fetchRulings();
    }, [cardRulingsUrl]);

    return (
        <>
            {rulings.length === 0 ? (
                <Typography variant="body1">
                    There are no rulings for this card.
                </Typography>
            ) : (
                rulings.map((ruling, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle2">
                            {ruling.published_at}
                        </Typography>
                        <Typography variant="body1">
                            {ruling.comment}
                        </Typography>
                        {index !== rulings.length - 1 && <Divider />}
                    </div>
                ))
            )}
        </>
    );
};

export default CardRulings;
