import { gql } from "@apollo/client";

const randomQueries = {
    SEND_FEEDBACK: gql`
        mutation SendFeedback($feedback: String!) {
            sendFeedback(feedback: $feedback)
        }
    `,
};

export default randomQueries;
