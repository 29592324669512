import { gql } from "@apollo/client";

const searchQueries = {
    MASTER_SEARCH_REQUEST: gql`
        query MasterSearchRequest($searchInput: String!) {
            masterSearchRequest(searchInput: $searchInput) {
                cards {
                    uuid
                    name
                    normal
                }
                decks {
                    id
                    name
                    slug
                }
                users {
                    id
                    displayName
                }
            }
        }
    `,
};

export default searchQueries;
