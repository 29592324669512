import React from "react";
import Avatar from "@mui/material/Avatar";

// for info on types of possible mana : https://mtg.fandom.com/wiki/Numbers_and_symbols
// images come from here : https://mtg.fandom.com/wiki/Category:Mana_symbols

// colorless
import C from "../static/mana/colorless/C.png";

// colorless
import U from "../static/mana/colored/U.png";
import B from "../static/mana/colored/B.png";
import R from "../static/mana/colored/R.png";
import G from "../static/mana/colored/G.png";
import W from "../static/mana/colored/W.png";

// generic
import zero from '../static/mana/generic/0.png'
import one from '../static/mana/generic/1.png'
import two from '../static/mana/generic/2.png'
import three from '../static/mana/generic/3.png'
import four from '../static/mana/generic/4.png'
import five from '../static/mana/generic/5.png'
import six from '../static/mana/generic/6.png'
import seven from '../static/mana/generic/7.png'
import eight from '../static/mana/generic/8.png'
import nine from '../static/mana/generic/9.png'
import ten from '../static/mana/generic/10.png'
import eleven from '../static/mana/generic/11.png'
import twelve from '../static/mana/generic/12.png'
import thirteen from '../static/mana/generic/13.png'
import fourteen from '../static/mana/generic/14.png'
import fifteen from '../static/mana/generic/15.png'
import sixteen from '../static/mana/generic/16.png'
import seventeen from '../static/mana/generic/17.png'
import eighteen from '../static/mana/generic/18.png'
import nineteen from '../static/mana/generic/19.png'
import twenty from '../static/mana/generic/20.png'
import X from '../static/mana/generic/X.png'

// hybrid
import BG from '../static/mana/hybrid/BG.png'
import BR from '../static/mana/hybrid/BR.png'
import GU from '../static/mana/hybrid/GU.png'
import GW from '../static/mana/hybrid/GW.png'
import RG from '../static/mana/hybrid/RG.png'
import RW from '../static/mana/hybrid/RW.png'
import UB from '../static/mana/hybrid/UB.png'
import UR from '../static/mana/hybrid/UR.png'
import WB from '../static/mana/hybrid/WB.png'
import WU from '../static/mana/hybrid/WU.png'

// monoColoredHybrid
import twoB from '../static/mana/monoColoredHybrid/2B.png'
import twoG from '../static/mana/monoColoredHybrid/2G.png'
import twoR from '../static/mana/monoColoredHybrid/2R.png'
import twoU from '../static/mana/monoColoredHybrid/2U.png'
import twoW from '../static/mana/monoColoredHybrid/2W.png'

// phyrexian
import BP from '../static/mana/phyrexian/BP.png'
import GP from '../static/mana/phyrexian/BP.png'
import RP from '../static/mana/phyrexian/BP.png'
import UP from '../static/mana/phyrexian/BP.png'
import WP from '../static/mana/phyrexian/BP.png'

// phyrexian hybrid
import BGP from '../static/mana/phyrexianHybrid/BGP.png'
import GUP from '../static/mana/phyrexianHybrid/GUP.png'
import GWP from '../static/mana/phyrexianHybrid/GWP.png'
import RGP from '../static/mana/phyrexianHybrid/RGP.png'
import RWP from '../static/mana/phyrexianHybrid/RWP.png'
import UBP from '../static/mana/phyrexianHybrid/UBP.png'
import URP from '../static/mana/phyrexianHybrid/URP.png'
import WBP from '../static/mana/phyrexianHybrid/WBP.png'
import WUP from '../static/mana/phyrexianHybrid/WUP.png'

// snow
import S from '../static/mana/snow/S.png'

// tap
import T from '../static/mana/tap/T.png'

const colorSymbols = {
    // colored
    'U': U,
    'B': B,
    'R': R,
    'G': G,
    'W': W,

    // colorless
    'C': C,

    // generic
    '0': zero,
    '1': one,
    '2': two,
    '3': three,
    '4': four,
    '5': five,
    '6': six,
    '7': seven,
    '8': eight,
    '9': nine,
    '10': ten,
    '11': eleven,
    '12': twelve,
    '13': thirteen,
    '14': fourteen,
    '15': fifteen,
    '16': sixteen,
    '17': seventeen,
    '18': eighteen,
    '19': nineteen,
    '20': twenty,
    'X': X,

    // hybrid
    'B/G': BG,
    'B/R': BR,
    'G/U': GU,
    'G/W': GW,
    'R/G': RG,
    'R/W': RW,
    'U/B': UB,
    'U/R': UR,
    'W/B': WB,
    'W/U': WU,

    // monoColoredHybrid
    '2/B': twoB,
    '2/G': twoG,
    '2/R': twoR,
    '2/U': twoU,
    '2/W': twoW,

    // phyrexian
    'B/P': BP,
    'G/P': GP,
    'R/P': RP,
    'U/P': UP,
    'W/P': WP,

    // phyrexian hybrid
    'B/G/P': BGP,
    'G/U/P': GUP,
    'G/W/P': GWP,
    'R/G/P': RGP,
    'R/W/P': RWP,
    'U/B/P': UBP,
    'U/R/P': URP,
    'W/B/P': WBP,
    'W/U/P': WUP,

    // snow
    'S': S,

    // tap
    'T': T,

};

// used in DeckList.jsx to render colors set for a deck in db
export const renderColorSymbols = (colors) => {
    // console.log('colors; ', colors)
    if (!Array.isArray(colors)) {
        return null; // Return null or handle appropriately for non-array values
    }

    if (colors.length === 0) {
        return <Avatar
            alt={"colorless"}
            src={colorSymbols["C"]}
            sx={{ width: 24, height: 24, marginRight: 1 }}
        />
    }

    return colors.map((color, index) => (
        <Avatar
            key={index}
            alt={color}
            src={colorSymbols[color]}
            sx={{ width: 24, height: 24, marginRight: 1 }}
        />
    ));
};

// used for cards mana_cost
export const manaCost = (colors) => {
    if (colors === null || colors === '') {
        return null;
    }

    if (typeof colors !== 'string') {
        return null; // Return null or handle appropriately for non-string values
    }

    const colorArray = colors
        .match(/\{[^}]*\}/g) // Extract text inside curly braces
        .map((color, index) => {
            const symbol = color.replace(/\{|\}/g, ''); // Remove curly braces
            return (
                <Avatar
                    key={index}
                    alt={symbol}
                    src={colorSymbols[symbol]}
                    sx={{ width: 24, height: 24, marginRight: 1 }}
                />
            );
        });

    return colorArray;
};

// used in cards oracle_text
export const replaceManaSymbols = (text) => {
    if (typeof text !== 'string') {
        return null; // Return null or handle appropriately for non-string values
    }

    const replacedText = text.replace(/\{[^}]*\}/g, (match) => {
        const symbol = match.replace(/\{|\}/g, ''); // Remove curly braces
        return `<img alt="${symbol}" src="${colorSymbols[symbol]}" style="width: 15px; height: 15px; margin-right: 1px;" />`;
    });

    return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
};


