import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    useMediaQuery,
    useTheme,
    Box,
    Typography,
    Divider,
    IconButton,
    Menu,
    MenuItem,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { FaDragon, FaFire, FaMountain, FaCrown } from "react-icons/fa";
import { GiJeweledChalice } from "react-icons/gi";
import { IoSparkles } from "react-icons/io5";
import { TbCards } from "react-icons/tb";
import { HiLightningBolt } from "react-icons/hi";
import { RiCoinLine } from "react-icons/ri";
import { MdOutlineQuestionMark } from "react-icons/md";
import useDeckSettingsStore from "../../stores/deckSettingsStore";
import { getFormattedTypelineFromCard } from "../../util/deckUtil";
import CardPreviewDrawer from "./CardPreviewDrawer";
import useDeckService from "../../hooks/useDeckService";
import ManageOwnershipModal from "./ManageOwnershipModal";

const Tile = ({ deckId, tile, onDeckUpdate, ownership }) => {
    const theme = useTheme();
    const cardRef = useRef(null);
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [previewCard, setPreviewCard] = useState(null);
    const [modalCardPreview, setModalCardPreview] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [openCardPreviewDrawer, setOpenCardPreviewDrawer] = useState(false);
    const [cardMenuState, setCardMenuState] = useState({});
    const [openOwnershipModal, setOpenOwnershipModal] = useState(false);
    const [cardOwnership, setCardOwnership] = useState(null);

    const [displayOwnershipText] = useDeckSettingsStore((state) => [
        state.displayOwnershipText,
    ]);

    const { removeCardFromDeck, updateCardTile } = useDeckService();

    const handleMouseEnter = (event, card) => {
        if (isDesktop) {
            setPreviewCard(card);
            updateMousePosition(event);
        }
    };

    const handleMouseLeave = () => {
        setPreviewCard(null);
    };

    const updateMousePosition = (event) => {
        setMousePosition({ x: event.clientX, y: event.clientY });
    };

    // Clear preview card when switching to mobile view
    useEffect(() => {
        if (!isDesktop) {
            setPreviewCard(null);
        }
    }, [isDesktop]);

    const handleCardPreviewModalOpen = (card) => {
        setModalCardPreview(card);
        setOpenCardPreviewDrawer(true);
    };

    const handleCardPreviewModalClose = () => {
        setModalCardPreview(null);
        setOpenCardPreviewDrawer(false);
    };

    const handleMenuClick = useCallback((event, card) => {
        setCardMenuState((prev) => ({
            ...prev,
            [card.uuid]: event.currentTarget,
        }));
    }, []);

    const handleMenuClose = useCallback((card) => {
        setCardMenuState((prev) => ({
            ...prev,
            [card.uuid]: null,
        }));
    }, []);

    const getCardPreviewPosition = () => {
        const windowHeight = window.innerHeight;
        const cardHeight = 400;

        if (mousePosition.y > windowHeight / 2) {
            // Lower half of the screen, anchor to the bottom left corner
            const top = mousePosition.y - cardHeight + 10;
            return {
                top: top, // Ensure the top position is not negative
                left: mousePosition.x + 10,
            };
        } else {
            // Upper half of the screen, anchor to the top left corner
            return {
                top: Math.max(0, mousePosition.y + 10),
                left: mousePosition.x + 10,
            };
        }
    };

    const handleDeleteCard = async (cardUid, quantity) => {
        try {
            const updatedDeckData = await removeCardFromDeck(
                deckId,
                cardUid,
                quantity
            );
            onDeckUpdate(updatedDeckData);
        } catch {
            console.log("error");
        }
    };

    const handleUpdateCardTile = async (
        deckId,
        cardUid,
        tileName,
        tileIcon
    ) => {
        try {
            const updatedDeckData = await updateCardTile(
                deckId,
                cardUid,
                tileName,
                tileIcon
            );
            onDeckUpdate(updatedDeckData);
        } catch {
            console.log("error updating card tile");
        }
    };

    const handleOpenOwnershipModal = (card) => {
        setCardOwnership(card);
        setOpenOwnershipModal(true);
    };

    const handleCloseOwnershipModal = () => {
        setCardOwnership(null);
        setOpenOwnershipModal(false);
    };

    const TileIcon = ({ tile }) => {
        // TBD : check if tile.icon has a set Tile icon else use default

        const iconStyle = {
            marginRight: "5px",
        };

        switch (tile.name) {
            case "Artifact":
                return <GiJeweledChalice style={iconStyle} />;
            case "Creature":
                return <FaDragon style={iconStyle} />;
            case "Enchantment":
                return <IoSparkles style={iconStyle} />;
            case "Sorcery":
                return <FaFire style={iconStyle} />;
            case "Instant":
                return <HiLightningBolt style={iconStyle} />;
            case "Land":
                return <FaMountain style={iconStyle} />;
            case "Token":
                return <RiCoinLine style={iconStyle} />;
            case "Commander":
                return <FaCrown style={iconStyle} />;
            case "Sideboard":
                return <TbCards style={iconStyle} />;
            default:
                return <MdOutlineQuestionMark style={iconStyle} />;
        }
    };

    return (
        <div>
            <Box
                sx={{
                    maxWidth: "100%",
                    m: "1",
                    textAlign: "left",
                    borderRadius: "10px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        pl: 1,
                    }}
                >
                    {/* change this icon to be dynamic */}
                    <TileIcon tile={tile} />
                    <Typography variant="h5">{tile.name} </Typography>
                    <Typography variant="h5" sx={{ ml: 1 }}>
                        ({tile.cardCount})
                    </Typography>
                </Box>
                <Divider />

                {tile.cards.map((card, idx) => (
                    <React.Fragment key={idx}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                m: 1,
                                height: "15px",
                            }}
                        >
                            <Typography sx={{ ml: 2, mr: 2 }}>
                                {card.count}
                            </Typography>
                            <Typography
                                onMouseEnter={(e) => {
                                    handleMouseEnter(e, card);
                                    updateMousePosition(e);
                                    e.target.style.textDecoration = "underline";
                                    e.target.style.cursor = "pointer";
                                }}
                                onMouseLeave={(e) => {
                                    handleMouseLeave();
                                    e.target.style.textDecoration = "none";
                                }}
                                onClick={() => handleCardPreviewModalOpen(card)}
                            >
                                {card.name}
                            </Typography>
                            {ownership && displayOwnershipText && (
                                <Typography
                                    onMouseEnter={(e) => {
                                        e.target.style.textDecoration =
                                            "underline";
                                        e.target.style.cursor = "pointer";
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.textDecoration = "none";
                                    }}
                                    sx={{
                                        // ml: 2,
                                        mr: 2,
                                        fontStyle: "italic",
                                        fontSize: "13px",
                                        color: "grey",
                                        marginLeft: "auto",
                                    }}
                                    onClick={() =>
                                        handleOpenOwnershipModal(card)
                                    }
                                >
                                    You Own: {card.ownershipCount}
                                </Typography>
                            )}
                            <IconButton
                                sx={{
                                    p: 0,
                                    marginLeft: !displayOwnershipText && "auto",
                                }}
                                size="small"
                                onClick={(event) =>
                                    handleMenuClick(event, card)
                                }
                            >
                                <KeyboardArrowDown />
                            </IconButton>
                            <Menu
                                id={`menu-${idx}`}
                                anchorEl={cardMenuState[card.uuid]}
                                open={Boolean(cardMenuState[card.uuid])}
                                onClose={() => handleMenuClose(card)}
                                PaperProps={{
                                    style: {
                                        boxShadow: "none",
                                        border: "1px solid #e0e0e0",
                                    },
                                }}
                            >
                                <MenuItem
                                    onClick={() =>
                                        handleCardPreviewModalOpen(card)
                                    }
                                >
                                    View Details
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        handleOpenOwnershipModal(card)
                                    }
                                >
                                    Manage Ownership
                                </MenuItem>
                                {/* <MenuItem
                                    onClick={() =>
                                        console.log("move to sideboard")
                                    }
                                >
                                    Move to Sideboard
                                </MenuItem> */}
                                {ownership && [
                                    tile.name !== "Sideboard" && (
                                        <MenuItem
                                            key="moveToSideboard"
                                            onClick={() =>
                                                handleUpdateCardTile(
                                                    deckId,
                                                    card.uuid,
                                                    "Sideboard",
                                                    "Sideboard"
                                                )
                                            }
                                        >
                                            Move to Sideboard
                                        </MenuItem>
                                    ),
                                    tile.name === "Sideboard" && (
                                        <MenuItem
                                            key="moveOutOfSideboard"
                                            onClick={() =>
                                                handleUpdateCardTile(
                                                    deckId,
                                                    card.uuid,
                                                    getFormattedTypelineFromCard(
                                                        card.typeLine
                                                    ),
                                                    getFormattedTypelineFromCard(
                                                        card.typeLine
                                                    )
                                                )
                                            }
                                        >
                                            Move out of Sideboard
                                        </MenuItem>
                                    ),
                                    <MenuItem
                                        key="removeOne"
                                        onClick={() =>
                                            handleDeleteCard(card.uuid, 1)
                                        }
                                        sx={{ color: "red" }}
                                    >
                                        Remove One
                                    </MenuItem>,
                                    card.count > 1 && (
                                        <MenuItem
                                            key="removeAll"
                                            onClick={() =>
                                                handleDeleteCard(
                                                    card.uuid,
                                                    card.count
                                                )
                                            }
                                            sx={{ color: "red" }}
                                        >
                                            Remove All
                                        </MenuItem>
                                    ),
                                ]}

                                {/* <MenuItem onClick={() => handleMenuClose(card)}>
                                    Move to Tile...
                                </MenuItem> */}
                            </Menu>
                        </Box>
                        {idx < tile.cards.length - 1 && (
                            <Divider sx={{ m: 1 }} />
                        )}
                    </React.Fragment>
                ))}
                {modalCardPreview && (
                    <CardPreviewDrawer
                        open={openCardPreviewDrawer}
                        onClose={handleCardPreviewModalClose}
                        card={modalCardPreview}
                    />
                )}
                {previewCard && isDesktop && (
                    <div
                        style={{
                            position: "fixed",
                            zIndex: 1000,
                            backgroundColor: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            boxShadow: theme.shadows[2],
                            width: "300px",
                            ...getCardPreviewPosition(),
                        }}
                    >
                        <img
                            ref={cardRef}
                            src={previewCard.normal}
                            alt={`Preview of ${previewCard.name}`}
                            style={{ maxWidth: "100%" }}
                        />
                    </div>
                )}
                <ManageOwnershipModal
                    open={openOwnershipModal}
                    onClose={handleCloseOwnershipModal}
                    deckId={deckId}
                    card={cardOwnership}
                    onDeckUpdate={onDeckUpdate}
                />
            </Box>
        </div>
    );
};

export default Tile;
