import { Typography, Skeleton, Grid, Box, Container } from "@mui/material";
import React from "react";

const ProfileDecks = () => {
    return (
        <Container>
            <Box sx={{ margin: { xs: 0, md: 5 } }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 2, position: "relative" }}
                    spacing={2}
                >
                    <Grid item xs={4}>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={100}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={100}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={100}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={100}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={100}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={100}
                        />
                    </Grid>
                    <Box
                        sx={{
                            mt: 1,
                            position: "absolute",
                            top: "30%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1,
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h5" align="center">
                            Profile Decks Coming Soon!
                        </Typography>
                    </Box>
                </Grid>
            </Box>
        </Container>
    );
};

export default ProfileDecks;
