import React, { useState } from "react";
import {
    Fab,
    Modal,
    Box,
    Typography,
    TextareaAutosize,
    Button,
} from "@mui/material";
import useRandomService from "../hooks/useRandomService";

const FeedbackButton = () => {
    const [open, setOpen] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");

    const { sendFeedback } = useRandomService();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFeedbackChange = (e) => {
        // Check if the length of the input text exceeds the limit
        if (e.target.value.length <= 255) {
            setFeedbackText(e.target.value);
        }
    };

    const handleFeedbackSubmit = () => {
        // Send feedback text to an email or your backend service

        try {
            const res = sendFeedback(feedbackText);
            console.log("feedback sent successfully", res);
        } catch (err) {
            console.error(err);
        }

        // Close the modal
        handleClose();
    };

    return (
        <>
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                onClick={handleOpen}
                sx={{
                    position: "fixed",
                    bottom: "70px",
                    right: "20px",
                }}
            >
                Feedback?
            </Fab>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="feedback-modal-title"
                aria-describedby="feedback-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        minWidth: 300,
                    }}
                >
                    <Typography
                        variant="h5"
                        id="feedback-modal-title"
                        gutterBottom
                    >
                        How can we improve?
                    </Typography>
                    <TextareaAutosize
                        aria-label="feedback-text"
                        placeholder="Enter your feedback..."
                        onChange={handleFeedbackChange}
                        value={feedbackText}
                        style={{
                            width: "100%",
                            marginBottom: "16px",
                            resize: "none",
                        }}
                        minRows={5}
                        maxLength={255}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFeedbackSubmit}
                        disabled={!feedbackText}
                    >
                        Send Feedback
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default FeedbackButton;
