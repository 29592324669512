import React, { useEffect, useState } from "react";
import { Typography, Avatar, IconButton, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../../context/AuthProvider";
import { useParams } from "react-router-dom";
import SnackbarMessage from "../../components/SnackbarMessage";
import ConfirmProfilePicModal from "./ConfirmProfilePicModal";
import { useTheme } from "@mui/material/styles";
import useUserService from "../../hooks/useUserService";
import ProfileDecks from "./ProfileDecks";

const Profile = () => {
    const [profilePicture, setProfilePicture] = useState(null);
    const [tempProfilePicture, setTempProfilePicture] = useState(null);
    const { authenticatedUser } = useAuth();
    const { displayName } = useParams();
    // const [bio, setBio] = useState(
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla hendrerit scelerisque rutrum."
    // );
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const {
        uploadProfilePicture,
        loadUserProfilePicture,
        errorUserProfilePicture,
        userProfilePicture,
    } = useUserService(displayName);

    useEffect(() => {
        if (
            !loadUserProfilePicture &&
            !errorUserProfilePicture &&
            userProfilePicture &&
            userProfilePicture.getProfilePicture
        ) {
            // If the query has completed successfully and the profile picture is retrieved
            setProfilePicture(
                `data:image/jpeg;base64, ${userProfilePicture.getProfilePicture}`
            );
        }
    }, [loadUserProfilePicture, errorUserProfilePicture, userProfilePicture]);

    const theme = useTheme();

    const handleConfirmModalClose = () => {
        setConfirmModalOpen(false);
        setTempProfilePicture(null);

        const fileInput = document.querySelector("input[type='file']");
        fileInput.value = null;
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size <= 2 * 1024 * 1024) {
                // Check if file size is less than 2MB
                const reader = new FileReader();
                setConfirmModalOpen(true);
                reader.onload = (e) => {
                    setTempProfilePicture(e.target.result);
                    // setProfilePicture(e.target.result);
                };
                reader.readAsDataURL(file);
            } else {
                console.log("enter file size exceed");
                setShowErrorSnackbar(true);
                setErrorMessage(
                    "File size exceeds 2MB limit. Please choose a smaller file."
                );
                setTimeout(() => {
                    setShowErrorSnackbar(false);
                }, 3000);
            }
        }
        console.log("file: ", file);
    };

    const saveProfilePicture = async (profilePicture) => {
        const base64Data = profilePicture.split(",")[1];

        // do backend request of image to save
        try {
            console.log("attempting profile pic upload");
            const res = await uploadProfilePicture(base64Data);
            console.log("res in pfp upload: ", res);
            setProfilePicture(profilePicture); // set profile pic
            setConfirmModalOpen(false); // close modal
            setShowSuccessSnackbar(true);
            setSuccessMessage("Profile picture has successfully been updated!");
            setTimeout(() => {
                setShowSuccessSnackbar(false);
            }, 3000);
        } catch (err) {
            console.log("error in pfp upload", err);
            setShowErrorSnackbar(true);
            setErrorMessage("Profile picture upload failed.");
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
        }

        // end backend request
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
                m: 3,
                height: "83vh",
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    cursor:
                        authenticatedUser?.displayName === displayName &&
                        "pointer",
                    "&:hover .overlay": {
                        opacity: 0.8,
                    },
                }}
            >
                <Avatar
                    src={profilePicture}
                    alt="Profile"
                    sx={{
                        width: 120,
                        height: 120,
                        border: "1px solid #616161",
                    }}
                />
                {authenticatedUser?.displayName === displayName && (
                    <Box
                        className="overlay"
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#00000088",
                            color: "white",
                            opacity: 0,
                            borderRadius: "50%",
                        }}
                        onClick={() =>
                            document.querySelector("input[type='file']").click()
                        }
                    >
                        <Typography variant="body2">Change Photo</Typography>
                    </Box>
                )}
            </Box>
            {authenticatedUser?.displayName === displayName && (
                <IconButton
                    sx={{
                        position: "absolute",
                        top: "120px",
                        left: "calc(50% + 50px)", // Adjust based on Avatar size
                        transform: "translate(-50%, -50%)",
                        border: "1px solid #616161",
                        backgroundColor: theme.palette.background.default,
                        width: "36px", // Set the width
                        height: "36px",
                        "&:hover": {
                            backgroundColor: theme.palette.background.default,
                        },
                    }}
                    onClick={() =>
                        document.querySelector("input[type='file']").click()
                    }
                >
                    <EditIcon
                        color="text"
                        sx={{
                            "&:hover": {
                                color: theme.palette.primary.main,
                            },
                        }}
                    />
                </IconButton>
            )}
            <Typography variant="h4" sx={{ mt: 2 }}>
                {displayName}
            </Typography>
            {/* <Typography variant="body1" sx={{ textAlign: "center", mt: 1 }}>
                {bio}
            </Typography> */}
            <ProfileDecks />
            <input
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                onChange={handleImageChange}
            />
            <SnackbarMessage
                open={showErrorSnackbar || showSuccessSnackbar}
                onClose={() => {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
                message={showErrorSnackbar ? errorMessage : successMessage}
                severity={showErrorSnackbar ? "error" : "success"}
            />
            <ConfirmProfilePicModal
                open={confirmModalOpen}
                onClose={handleConfirmModalClose}
                profilePicture={tempProfilePicture}
                onSave={saveProfilePicture}
            />
        </Box>
    );
};

export default Profile;
