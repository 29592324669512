import {
    Typography,
    useMediaQuery,
    Divider,
    TextField,
    Box,
    Button,
} from "@mui/material";

import React from "react";
import CustomModal from "../../components/CustomModal";

const BulkEditModal = ({ open, onClose, cards }) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");

    const handleBulkEditSave = () => {
        // do stuff
    };

    return (
        <>
            <CustomModal
                open={open}
                onClose={onClose}
                style={{
                    textAlign: "left",
                    width: isDesktop ? "550px" : "90%",
                    height: isDesktop ? "480px" : "60%",
                }}
            >
                <Typography variant="h5">Bulk Edit</Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Typography
                    variant="body1"
                    sx={{ fontSize: "15px", fontStyle: "italic" }}
                >
                    Note: Bulk Edit format is
                    <code>"(Count) (Card Name) (Set) (Tile Name)"</code>
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ fontSize: "15px", fontStyle: "italic", mb: 2 }}
                >
                    Defaults will be set if (Set) and (Tile Name) are excluded.
                </Typography>
                <TextField
                    rows={10}
                    multiline
                    value={cards}
                    placeholder="Paste your deck list here..."
                    style={{ width: "100%", resize: "vertical" }}
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "right",
                        mt: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                        sx={{ mr: 2 }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBulkEditSave}
                    >
                        Save
                    </Button>
                </Box>
            </CustomModal>
        </>
    );
};

export default BulkEditModal;
