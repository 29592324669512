import React from "react";
import { Grid, Typography, Skeleton } from "@mui/material";
import LittleWizard from "../../static/littleWizard.png";
import { Box } from "@mui/system";

const NoCardsSkeleton = () => {
    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
        <Skeleton
            key={index}
            variant="rectangular"
            width="90%"
            height={40}
            sx={{
                marginLeft: "auto",
                marginRight: "auto",
                mt: index > 0 ? 2 : 0,
            }}
        />
    ));

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 2, position: "relative" }}
        >
            <Grid item xs={6}>
                {skeletonRows}
            </Grid>
            <Grid item xs={6}>
                {skeletonRows}
            </Grid>
            <Box
                sx={{
                    mt: 1,
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <img
                    src={LittleWizard}
                    style={{
                        width: "200px",
                        marginBottom: "10px",
                    }}
                    alt="littleWizard"
                />
                <Typography variant="h5" align="center">
                    Infuse some magic into your spell sheet! Add cards using the
                    search.
                </Typography>
            </Box>
        </Grid>
    );
};

export default NoCardsSkeleton;
