import { useMutation } from "@apollo/client";
import authenticationQueries from "../graphql/AuthenticationQueries";
import Cookies from "js-cookie";
import { useAuth } from "../context/AuthProvider";

const useAuthenticationService = () => {
    const { setAuthenticatedUser } = useAuth();

    const [loginMutation] = useMutation(authenticationQueries.LOGIN_MUTATION);
    const [signUpMutation] = useMutation(
        authenticationQueries.SIGN_UP_MUTATION
    );

    const login = async (email, password) => {
        try {
            const { data } = await loginMutation({
                variables: { email, password },
            });

            const { authenticate } = data;

            if (authenticate.errorMessage) {
                throw new Error(authenticate.errorMessage);
            }

            Cookies.set("accessToken", authenticate.accessToken, {
                expires: 1,
            });

            setAuthenticatedUser(authenticate);

            return { success: true, message: "Log In Successful. Enjoy!" };
        } catch (error) {
            if (error.message === "USER_NOT_FOUND") {
                return { success: false, message: "User not found." };
            }

            // Handle other cases or return a generic error message
            return {
                success: false,
                message: "An unexpected error occurred during login.",
            };
        }
    };

    const signup = async (email, displayName, password) => {
        try {
            const { data } = await signUpMutation({
                variables: { email, displayName, password },
            });

            const { signUp } = data;

            if (signUp !== "Success") {
                throw new Error(signUp);
            }

            return {
                success: true,
                message:
                    "Account successfully created. Redirecting to Log In...",
            };
        } catch (error) {
            return {
                success: false,
                message: error.message,
            };
        }
    };

    return {
        login,
        signup,
    };
};

export default useAuthenticationService;
