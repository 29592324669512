import React, { useEffect, useState } from "react";
import {
    Grid,
    Box,
    CircularProgress,
    Tabs,
    Tab,
    Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useCardService from "../../hooks/useCardService";
import { manaCost, replaceManaSymbols } from "../../util/colorsUtils";
import CardRulings from "../SingleDeck/CardRulings";
import CardLegalities from "../SingleDeck/CardLegalities";
import CardPurchase from "../SingleDeck/CardPuchase";

import Footer from "../../components/Footer";

const CardView = () => {
    const { uuid } = useParams();
    const [card, setCard] = useState(null);
    const [tabValue, setTabValue] = useState("one");

    const { useFindCardByUuid } = useCardService();
    const { dataFindCardByUuid, loadingFindCardByUuid, refetchFindCardByUuid } =
        useFindCardByUuid(uuid);

    useEffect(() => {
        refetchFindCardByUuid();

        if (dataFindCardByUuid) {
            console.log("data: ", dataFindCardByUuid);
            setCard(dataFindCardByUuid.findCardByUuid);
        }
    }, [refetchFindCardByUuid, dataFindCardByUuid]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            {loadingFindCardByUuid ? (
                <CircularProgress />
            ) : (
                <Box>
                    <Grid container spacing={1} sx={{ mt: 3 }}>
                        <Grid item xs={5}>
                            <Box sx={{ textAlign: "center" }}>
                                <img
                                    src={card?.normal}
                                    style={{ width: "400px" }}
                                    alt={card?.name}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={7}>
                            <Box sx={{ width: "100%" }}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    aria-label="wrapped label tabs example"
                                >
                                    <Tab value="one" label="Details" wrapped />
                                    <Tab value="two" label="Rulings" />
                                    <Tab value="three" label="Legalities" />
                                    <Tab value="four" label="Purchase" />
                                </Tabs>
                                <Box sx={{ width: "80%", mt: 2 }}>
                                    <Box
                                        role="tabpanel"
                                        hidden={tabValue !== "one"}
                                    >
                                        <Box sx={{ display: "flex", mb: 2 }}>
                                            {manaCost(card?.manaCost)}
                                        </Box>
                                        {replaceManaSymbols(card?.oracleText)}
                                        {card?.flavorText !== null && (
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontStyle: "italic",
                                                    mt: 1,
                                                }}
                                            >
                                                "{card?.flavorText}"
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box
                                        role="tabpanel"
                                        hidden={tabValue !== "two"}
                                    >
                                        <CardRulings
                                            cardRulingsUrl={card?.rulingsUri}
                                        />
                                    </Box>
                                    <Box
                                        role="tabpanel"
                                        hidden={tabValue !== "three"}
                                    >
                                        <CardLegalities card={card} />
                                    </Box>
                                    <Box
                                        role="tabpanel"
                                        hidden={tabValue !== "four"}
                                    >
                                        <CardPurchase card={card} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {/* <Footer /> */}
        </>
    );
};

export default CardView;
