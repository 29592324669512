import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    OutlinedInput,
    InputAdornment,
    Divider,
    IconButton,
    Tooltip,
    Paper,
    List,
    ListItemButton,
    CircularProgress,
    Typography,
    ListItem,
} from "@mui/material";
import { MdOutlineExplore } from "react-icons/md";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
// import { useTheme } from "@mui/material/styles";
import useSearchService from "../../hooks/useSearchService";

const MasterSearchBar = () => {
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const [hoveredOption, setHoveredOption] = useState(null);

    // const theme = useTheme();

    const { useMasterSearchRequest } = useSearchService();
    const { getSearch, dataMasterSearch } = useMasterSearchRequest();

    useEffect(() => {
        const updatedResults = [];

        if (dataMasterSearch) {
            updatedResults.push({
                category: "Cards",
                results: dataMasterSearch.masterSearchRequest.cards,
            });
            updatedResults.push({
                category: "Decks",
                results: dataMasterSearch.masterSearchRequest.decks,
            });
            updatedResults.push({
                category: "Users",
                results: dataMasterSearch.masterSearchRequest.users,
            });
        }

        if (updatedResults.length > 0) {
            setSearchResults(updatedResults);
        }
        setLoading(false);
    }, [dataMasterSearch]);

    // delays search request made by 2 seconds
    useEffect(() => {
        if (searchInput !== "") {
            const timeoutId = setTimeout(() => {
                getSearch({ variables: { searchInput: searchInput } });
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [searchInput, getSearch]);

    const handleSearchInputChange = (event) => {
        setLoading(true);

        setSearchInput(event.target.value);
    };

    const handleResultClick = (category, result) => {
        if (category === "Users") {
            navigate(`/user/${result}`);
        } else if (category === "Cards") {
            navigate(`/card/${result}`);
        } else if (category === "Decks") {
            navigate(`/decks/${result}`);
        }

        // Handle click on search result
        console.log("Clicked on:", result);
        // Add navigation logic here
    };

    // const handleSeeAll = (category) => {
    //     if (category === "Users") {
    //         navigate(`/explore/users?displayName=${searchInput}`);
    //     } else if (category === "Cards") {
    //         navigate(`/explore/cards?name=${searchInput}`);
    //     } else if (category === "Decks") {
    //         navigate(`/explore/decks?name=${searchInput}`);
    //     }
    // };

    const handleOutsideClick = (event) => {
        if (
            inputRef.current &&
            !inputRef.current.contains(event.target) &&
            event.target.tagName !== "BUTTON" &&
            event.target.tagName !== "LI"
        ) {
            setShowDropdown(false);
        }
    };

    const handleInputFocus = () => {
        setShowDropdown(true);
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const handleOptionMouseEnter = (option) => {
        setHoveredOption(option);
    };

    const handleOptionMouseLeave = () => {
        setHoveredOption(null);
    };

    return (
        <Box
            sx={{
                position: "relative",
                width: "60%",
            }}
        >
            <OutlinedInput
                id="outlined-basic"
                placeholder="Search cards, decks, and users"
                fullWidth
                sx={{
                    borderRadius: "50px",
                    width: "100%",
                    height: "50px",
                }}
                startAdornment={
                    <InputAdornment position="start" sx={{ fontSize: "25px" }}>
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment
                        position="start"
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        {loading && (
                            <CircularProgress size={24} sx={{ mr: 2 }} />
                        )}
                        <Divider
                            orientation="vertical"
                            style={{
                                marginRight: "10px",
                                backgroundColor: "primary",
                                height: "35px",
                            }}
                        />
                        <Tooltip title="Explore">
                            <IconButton
                                sx={{ fontSize: "25px" }}
                                onClick={() => navigate(`/explore`)}
                            >
                                <MdOutlineExplore />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                }
                value={searchInput}
                onChange={handleSearchInputChange}
                onFocus={handleInputFocus}
                inputRef={inputRef}
                autoComplete="off"
            />
            {showDropdown && (
                <Paper
                    elevation={3}
                    sx={{
                        position: "absolute",
                        width: "100%",
                        zIndex: "3",
                        top: "100%",
                        overflow: "auto",
                        ...(searchResults.length > 0
                            ? { maxHeight: "300px" }
                            : { height: "70px" }),
                    }}
                >
                    {searchResults.some(
                        (category) => category.results.length > 0
                    ) ? (
                        searchResults.map((category, index) => (
                            <Box key={index}>
                                {category.results.length > 0 && (
                                    <List>
                                        <ListItem /* <ListItemButton> */
                                            variant="subtitle1"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                fontWeight: "bold",
                                                paddingLeft: "10px",
                                                // cursor: "pointer",
                                            }}
                                            // onClick={() =>
                                            //     handleSeeAll(category.category)
                                            // }
                                        >
                                            <span>{category.category}</span>
                                            {/* <span
                                                style={{
                                                    color: theme.palette.text
                                                        .main,
                                                    marginRight: "10px",
                                                    fontWeight: "normal",
                                                }}
                                            >
                                                View all {category.category}{" "}
                                                results
                                            </span> */}
                                            {/* </ListItemButton> */}
                                        </ListItem>
                                        <Divider />
                                        {category.results.map((result, i) => (
                                            <>
                                                {category.category ===
                                                    "Cards" && (
                                                    <>
                                                        {hoveredOption ===
                                                            result && (
                                                            <img
                                                                src={
                                                                    result.normal
                                                                }
                                                                alt={
                                                                    result.name
                                                                }
                                                                style={{
                                                                    position:
                                                                        "fixed",
                                                                    top: "23%",
                                                                    left: "20%",
                                                                    transform:
                                                                        "translate(-50%, -50%)",
                                                                    width: "200px",
                                                                    zIndex: 9999,
                                                                }}
                                                            />
                                                        )}
                                                        <ListItemButton
                                                            key={i}
                                                            onClick={() =>
                                                                handleResultClick(
                                                                    category.category,
                                                                    result.uuid
                                                                )
                                                            }
                                                            onMouseEnter={() =>
                                                                handleOptionMouseEnter(
                                                                    result
                                                                )
                                                            }
                                                            onMouseLeave={
                                                                handleOptionMouseLeave
                                                            }
                                                        >
                                                            {result.name}
                                                        </ListItemButton>
                                                    </>
                                                )}
                                            </>
                                        ))}
                                        {category.results.map((result, i) => (
                                            <>
                                                {category.category ===
                                                    "Decks" && (
                                                    <ListItemButton
                                                        key={i}
                                                        onClick={() =>
                                                            handleResultClick(
                                                                category.category,
                                                                result.slug
                                                            )
                                                        }
                                                    >
                                                        {result.name}
                                                    </ListItemButton>
                                                )}
                                            </>
                                        ))}
                                        {category.results.map((result, i) => (
                                            <>
                                                {category.category ===
                                                    "Users" && (
                                                    <ListItemButton
                                                        key={i}
                                                        onClick={() =>
                                                            handleResultClick(
                                                                category.category,
                                                                result.displayName
                                                            )
                                                        }
                                                    >
                                                        {result.displayName}
                                                    </ListItemButton>
                                                )}
                                            </>
                                        ))}
                                    </List>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Box sx={{ textAlign: "center", padding: "20px" }}>
                            {loading ? (
                                <Typography variant="body1">Loading</Typography>
                            ) : (
                                <Typography variant="body1">
                                    No results found
                                </Typography>
                            )}
                        </Box>
                    )}
                </Paper>
            )}
        </Box>
    );
};

export default MasterSearchBar;
