import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ApolloWrapper from "./ApolloWrapper";
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryFallback from "./components/ErrorBoundaryFallback";

ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <React.StrictMode>
            <ApolloWrapper />
        </React.StrictMode>
    </ErrorBoundary>,
    document.getElementById("root")
);

reportWebVitals();
