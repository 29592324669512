import { useLazyQuery } from "@apollo/client";
import searchQueries from "../graphql/SearchQueries";

const useSearchService = () => {
    const useMasterSearchRequest = () => {
        const [
            getSearch,
            {
                data: dataMasterSearch,
                loading: loadingMasterSearch,
                error: errorMasterSearch,
            },
        ] = useLazyQuery(searchQueries.MASTER_SEARCH_REQUEST);

        return {
            getSearch,
            dataMasterSearch,
            loadingMasterSearch,
            errorMasterSearch,
        };
    };

    return { useMasterSearchRequest };
};

export default useSearchService;
