import React, { useState } from "react";
import {
    Typography,
    Divider,
    Box,
    Grid,
    Radio,
    FormControlLabel,
    Button,
    Checkbox,
    useMediaQuery,
} from "@mui/material";
import CustomModal from "../../components/CustomModal";
import cardkingdom from "../../static/storeIcons/cardkingdom.png";
import tcgplayer from "../../static/storeIcons/tcgplayer.png";
import cardhoarder from "../../static/storeIcons/cardhoarder.png";
import { useTheme } from "@mui/material/styles";

const BuyDeckModal = ({ open, onClose }) => {
    const [selectedOption, setSelectedOption] = useState("");
    const [includeRelatedTokens, setIncludeRelatedTokens] = useState(false);
    const isDesktop = useMediaQuery("(min-width: 600px)");

    const theme = useTheme();

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        setIncludeRelatedTokens(event.target.checked);
    };

    const handleBuyNowClick = () => {
        switch (selectedOption) {
            case "Card Kingdom":
                console.log("card kingdom");
                break;
            case "TCGPlayer":
                console.log("TCGPlayer");
                break;
            case "Cardhoarder":
                console.log("Cardhoarder");
                break;
        }
    };

    return (
        <>
            <CustomModal
                open={open}
                onClose={onClose}
                style={{
                    textAlign: "left",
                    width: isDesktop ? "550px" : "90%",
                    height: isDesktop ? "300px" : "40%",
                }}
            >
                <Typography variant="h5">Buy Deck</Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Grid
                    container
                    sx={{ display: "flex", m: 2, textAlign: "center" }}
                >
                    <Grid item md={4} sm={12}>
                        <FormControlLabel
                            value="Card Kingdom"
                            control={
                                <Radio
                                    color="primary"
                                    sx={{
                                        "&.Mui-checked": {
                                            color: "#1976d2",
                                        },
                                    }}
                                    checked={selectedOption === "Card Kingdom"}
                                    onChange={handleOptionChange}
                                    value="Card Kingdom"
                                />
                            }
                            label={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "15px",
                                        marginRight: "2px",
                                    }}
                                >
                                    <img
                                        src={cardkingdom}
                                        alt="Card Kingdom"
                                        style={{
                                            width: "21px",
                                            marginRight: "2px",
                                        }}
                                    />
                                    Card Kingdom
                                </Box>
                            }
                            sx={{
                                width: "98%",
                                border: `1px solid ${theme.palette.text.main}`,
                                borderRadius: "10px",
                            }}
                        />
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <FormControlLabel
                            value="TCGPlayer"
                            control={
                                <Radio
                                    color="primary"
                                    sx={{
                                        "&.Mui-checked": {
                                            color: "#1976d2",
                                        },
                                    }}
                                    checked={selectedOption === "TCGPlayer"}
                                    onChange={handleOptionChange}
                                    value="TCGPlayer"
                                />
                            }
                            label={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "15px",
                                        marginRight: "2px",
                                    }}
                                >
                                    <img
                                        src={tcgplayer}
                                        alt="TCGPlayer"
                                        style={{ width: "25px" }}
                                    />
                                    TCGPlayer
                                </Box>
                            }
                            sx={{
                                width: "95%",
                                border: `1px solid ${theme.palette.text.main}`,
                                borderRadius: "10px",
                            }}
                        />
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <FormControlLabel
                            value="Cardhoarder"
                            control={
                                <Radio
                                    color="primary"
                                    sx={{
                                        "&.Mui-checked": {
                                            color: "#1976d2",
                                        },
                                    }}
                                    checked={selectedOption === "Cardhoarder"}
                                    onChange={handleOptionChange}
                                    value="Cardhoarder"
                                />
                            }
                            label={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "15px",
                                        marginRight: "2px",
                                    }}
                                >
                                    <img
                                        src={cardhoarder}
                                        alt="Cardhoarder"
                                        style={{ width: "25px" }}
                                    />
                                    Cardhoarder
                                </Box>
                            }
                            sx={{
                                width: "95%",
                                border: `1px solid ${theme.palette.text.main}`,
                                borderRadius: "10px",
                            }}
                        />
                    </Grid>
                </Grid>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Purchase Options:
                </Typography>
                <Grid container alignItems="left" justifyContent="left">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeRelatedTokens}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label={
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: "14px" }}
                                >
                                    Include related tokens
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "right",
                        mt: 3,
                        mr: 3,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBuyNowClick}
                    >
                        Buy Now
                    </Button>
                </Box>
            </CustomModal>
        </>
    );
};

export default BuyDeckModal;
