import React, { useEffect } from "react";
import {
    Button,
    Typography,
    Box,
    List,
    Divider,
    ListItem,
    Checkbox,
} from "@mui/material";
import CustomModal from "./CustomModal";
import CloseIcon from "@mui/icons-material/Close";
import useUserSettingsStore from "../stores/userSettingsStore";
import {
    getCurrentVersion,
    getStoredVersion,
    setStoredVersion,
} from "../util/versionControl";

const NewFeaturesModal = ({ open, onClose }) => {
    const { showNewFeaturesModal, toggleShowNewFeaturesModal } =
        useUserSettingsStore();

    useEffect(() => {
        const currentVersion = getCurrentVersion();
        const storedVersion = getStoredVersion();

        if (storedVersion !== currentVersion) {
            // Version has changed, reset showNewFeaturesModal to true
            toggleShowNewFeaturesModal();
            setStoredVersion(currentVersion);
        }
    }, [toggleShowNewFeaturesModal]);

    if (!showNewFeaturesModal) {
        return null;
    }

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            aria-labelledby="new-features-modal-title"
            aria-describedby="new-features-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    // minWidth: 300,
                    width: "600px",
                }}
            >
                <Box sx={{ display: "flex" }}>
                    <Box flexGrow={1} />
                    <Button
                        sx={{ width: "20px" }}
                        color="text"
                        // variant="contained"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </Button>
                </Box>
                <Typography
                    variant="h4"
                    id="new-features-modal-title"
                    gutterBottom
                >
                    What's New!
                </Typography>
                <Divider />
                <Box sx={{ mt: 1 }}>
                    <Typography variant="h6" gutterBottom>
                        Version {getCurrentVersion()}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Enhancements:
                    </Typography>
                    <List
                        sx={{
                            listStyleType: "disc",
                            listStylePosition: "inside",
                        }}
                    >
                        <ListItem sx={{ display: "list-item" }}>
                            Auto adding related cards on deck additions /
                            imports
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            Sideboard in decks
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            Paste Import supoprt
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            Commander formats supoprt
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            Deck Tags (can add and remove from Deck Details
                            Drawer)
                        </ListItem>
                    </List>

                    <Typography variant="h6" gutterBottom>
                        Bug Fixes:
                    </Typography>
                    <List
                        sx={{
                            listStyleType: "disc",
                            listStylePosition: "inside",
                        }}
                    >
                        <ListItem sx={{ display: "list-item" }}>
                            Cleanup / refactor of database cards
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            Styling fixes
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>etc.</ListItem>
                    </List>
                    <Typography variant="h6" gutterBottom>
                        We're working on:
                    </Typography>
                    <List
                        sx={{
                            listStyleType: "disc",
                            listStylePosition: "inside",
                        }}
                    >
                        <ListItem sx={{ display: "list-item" }}>
                            URL Imports
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            Ability to categorize cards into new "Tiles"
                        </ListItem>
                        <ListItem sx={{ display: "list-item" }}>
                            Collections Page
                        </ListItem>
                    </List>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                        <Checkbox
                            checked={!showNewFeaturesModal}
                            onChange={toggleShowNewFeaturesModal}
                        />
                        <Typography variant="body1">
                            Don't see this again
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </CustomModal>
    );
};

export default NewFeaturesModal;
