// update this to group duplicate cards and add counts
const transformDeckData = (deckCards) => {
    const groupedData = {};
    let tileID = 1;


    deckCards.forEach((deckCard, idx) => {
        console.log('deckCard: ', deckCard)
        const tileName = deckCard.tile.name;
        const cardUuid = deckCard.card.uuid;
        const ownership = deckCard.own;

        if (!groupedData[tileName]) {
            groupedData[tileName] = {
                id: tileID++,
                name: tileName,
                cardCount: 0,
                cards: [],
            };
        }

        const existingCard = groupedData[tileName].cards.find(
            (c) => c.uuid === cardUuid
        );

        groupedData[tileName].cardCount++;


        if (existingCard) {
            existingCard.count += 1; // Increment count if the card already exists
            if (ownership) {
                existingCard.ownershipCount += 1;
            }
        } else {
            groupedData[tileName].cards.push({
                ...deckCard.card,
                count: 1,
                ownershipCount: ownership ? 1 : 0,
            });
        }

    });

    const resultArray = Object.values(groupedData);
    // Custom sorting logic to move the group with name "Commander" to the beginning
    resultArray.sort((a, b) => {
        if (a.name === "Commander") {
            return -1; // Move "Commander" group to the beginning
        } else if (b.name === "Commander") {
            return 1; // Move "Commander" group to the beginning
        } else if (a.name === "Token" || a.name === "Sideboard") {
            return 1; // Move "Token" or "Sideboard" group to the end
        } else if (b.name === "Token" || b.name === "Sideboard") {
            return -1; // Move "Token" or "Sideboard" group to the end
        } else {
            return a.name.localeCompare(b.name); // Sort other groups by name
        }
    });
    // Sort cards within each group
    resultArray.forEach((group) => {
        group.cards.sort((a, b) => a.name.localeCompare(b.name));
    });
    return resultArray;
};

// format cards for export, bulk edit, buy cards, etc... possible options below
/*
option = 1:
1 Card Name
3 Card Name
2 Card Name

option = 2:
1x Card Name
3x Card Name
2x Card Name

option = 3:
1 Card Name (set)
3 Card Name (set)
2 Card Name (set)

option = 4: (only used in bulk edit really)
1 Card Name (set) (tile name)
3 Card Name (set) (tile name)
2 Card Name (set) (tile name)
*/

const formatForExport = (tiles, option) => {

    if (tiles) {
        let formattedDeck = [];
        let multiLineReturn;

        tiles.forEach(tile => {
            if (tile.name !== 'Sideboard') {
                tile.cards.forEach(card => {
                    const { count, name, set } = card;

                    if (option === "1") {
                        formattedDeck.push(`${count} ${name}`);
                    } else if (option === "2") {
                        formattedDeck.push(`${count}x ${name}`);
                    } else if (option === "3") {
                        const formattedSet = set.toUpperCase()
                        formattedDeck.push(`${count} ${name} (${formattedSet})`);
                    } else {
                        const formattedSet = set.toUpperCase()
                        formattedDeck.push(`${count} ${name} (${formattedSet}) (${tile.name})`);
                    }
                });
            }
        });

        // if a sideboard tile exists append it
        const sideboardTile = tiles.find(tile => tile.name === 'Sideboard');
        if (sideboardTile) {
            formattedDeck.push('\nSIDEBOARD:');
            sideboardTile.cards.forEach(card => {
                const { count, name, set } = card;
                if (option === 1) {
                    formattedDeck.push(`${count} ${name}`);
                } else if (option === 2) {
                    formattedDeck.push(`${count}x ${name}`);
                } else {
                    const formattedSet = set.toUpperCase()
                    formattedDeck.push(`${count} ${name} (${formattedSet})`);
                }
            });
        }

        multiLineReturn = formattedDeck.join('\n');

        // console.log(multiLineReturn)
        return multiLineReturn

    }
}

const getFormattedTypelineFromCard = (cardTypeLine) => {
    if (!cardTypeLine) {
        return null;
    }

    if (cardTypeLine.includes("Token")) {
        return "Token";
    } else if (cardTypeLine.includes("Creature")) {
        return "Creature";
    } else if (cardTypeLine.includes("Land")) {
        return "Land";
    } else if (cardTypeLine.includes("Instant")) {
        return "Instant";
    } else if (cardTypeLine.includes("Planeswalker")) {
        return "Instant";
    } else if (cardTypeLine.includes("Enchantment")) {
        return "Enchantment";
    } else if (cardTypeLine.includes("Artifact")) {
        return "Artifact";
    } else if (cardTypeLine.includes("Sorcery")) {
        return "Sorcery";
    }

    return "Sideboard";
}


export { transformDeckData, formatForExport, getFormattedTypelineFromCard };
