import React from "react";

const DeckGrid = ({ decks }) => {
    return (
        <>
            <h1>Deck Grid</h1>
        </>
    );
};

export default DeckGrid;
