// theme.js
import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: '#2b82b7',
        },
        secondary: {
            main: '#b8b8b8',
        },
        background: {
            default: "#fff",
            paper: '#FAFAFA'
        },
        text: {
            main: '#616161'
        }
    },
});

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: '#2b82b7',
        },
        secondary: {
            main: '#969696',
        },
        background: {
            default: '#1A1A1A',
            paper: '#2e2d2d',
        },
        text: {
            main: '#f7f7f7'
        }
    },

});

export { lightTheme, darkTheme };
