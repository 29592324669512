// Navbar.jsx
import React, { useState } from "react";
import { Toolbar, Button, useMediaQuery, Box } from "@mui/material";
import LoginModal from "../Auth/LoginModal";
import SignupModal from "../Auth/SignupModal";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import ProfileIcon from "./ProfileIcon";
import MasterSearchBar from "./MasterSearchBar";
import SpellSheetsLogoLight from "../../static/Spell-Sheets-logo-light-theme.png";
import SpellSheetsLogoDark from "../../static/Spell-Sheets-logo-dark-theme.png";
import SpellSheetsIcon from "../../static/Spell-Sheets-Icon.png";
import useUserSettingsStore from "../../stores/userSettingsStore";

const Navbar = () => {
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const [signupModalOpen, setSignupModalOpen] = useState(false);

    const { theme } = useUserSettingsStore();
    const isDesktop = useMediaQuery("(min-width: 600px)");

    const Logo = () => {
        return isDesktop ? (
            theme === "light" ? (
                <img
                    src={SpellSheetsLogoLight}
                    style={{ width: "220px", margin: "5px" }}
                />
            ) : (
                <img
                    src={SpellSheetsLogoDark}
                    style={{ width: "220px", margin: "5px" }}
                />
            )
        ) : (
            <img
                src={SpellSheetsIcon}
                style={{ width: "50px", margin: "5px" }}
            />
        );
    };

    const { authenticatedUser } = useAuth();

    const handleLoginModalOpen = () => {
        setLoginModalOpen(true);
    };

    const handleLoginModalClose = () => {
        setLoginModalOpen(false);
    };

    const handleSignupModalOpen = () => {
        if (!loginModalOpen) {
            setLoginModalOpen(true);
        }
        setSignupModalOpen(true);
    };

    const handleSignupModalClose = () => {
        setSignupModalOpen(false);
        setLoginModalOpen(false);
    };

    const showSignUp = () => {
        handleLoginModalClose();
        handleSignupModalOpen();
    };

    const handleBackToLogin = () => {
        setLoginModalOpen(true);
        setSignupModalOpen(false);
    };

    return (
        <Toolbar
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            {/* , width: "320px" */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Link
                    to="/"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    {/* <Typography variant="h5">Spell Sheets</Typography> */}
                    <Logo />
                </Link>
            </Box>
            <Box
                sx={{
                    flex: "1", // Takes up remaining space
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <MasterSearchBar />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {authenticatedUser ? (
                    <>
                        <ProfileIcon />
                    </>
                ) : (
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleLoginModalOpen}
                        sx={{ mr: 2, width: "160px" }}
                    >
                        Log In / Sign Up
                    </Button>
                )}
            </Box>
            <LoginModal
                open={loginModalOpen}
                onClose={handleLoginModalClose}
                showSignUp={showSignUp}
            />

            <SignupModal
                open={signupModalOpen}
                onClose={handleSignupModalClose}
                onBackToLogin={handleBackToLogin}
            />
        </Toolbar>
    );
};

export default Navbar;
