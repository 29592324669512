import React from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Grid,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const CardLegalities = ({ card }) => {
    const LegalityIcon = ({ legality }) => {
        if (legality === "not_legal") {
            return <CloseIcon sx={{ mr: 2 }} />;
        } else {
            return <CheckIcon sx={{ mr: 2 }} />;
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.alchemy} />
                                <ListItemText primary="Alchemy" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.brawl} />
                                <ListItemText primary="Brawl" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.commander} />
                                <ListItemText primary="Commander" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.duel} />
                                <ListItemText primary="Duel" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.explorer} />
                                <ListItemText primary="Explorer" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.future} />
                                <ListItemText primary="Future" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.gladiator} />
                                <ListItemText primary="Gladiator" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.historic} />
                                <ListItemText primary="Historic" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.historicBrawl} />
                                <ListItemText primary="Historic Brawl" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.legacy} />
                                <ListItemText primary="Legacy" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.modern} />
                                <ListItemText primary="Modern" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item xs={6}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.oathbreaker} />
                                <ListItemText primary="Oathbreaker" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.oldSchool} />
                                <ListItemText primary="Oldschool" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.pauper} />
                                <ListItemText primary="Pauper" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon
                                    legality={card?.pauperCommander}
                                />
                                <ListItemText primary="Pauper Commander" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.penny} />
                                <ListItemText primary="Penny" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.pioneer} />
                                <ListItemText primary="Pioneer" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.predh} />
                                <ListItemText primary="PreDH" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.premodern} />
                                <ListItemText primary="Premodern" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.standard} />
                                <ListItemText primary="Standard" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <LegalityIcon legality={card?.vintage} />
                                <ListItemText primary="Vintage" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
};

export default CardLegalities;
