import React from "react";
import {
    Drawer,
    useMediaQuery,
    Box,
    Typography,
    Button,
    Divider,
    Switch,
} from "@mui/material";
import { useAuth } from "../../context/AuthProvider";
import CloseIcon from "@mui/icons-material/Close";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import { AiOutlineUser } from "react-icons/ai";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Brightness5OutlinedIcon from "@mui/icons-material/Brightness5Outlined";
import Brightness2OutlinedIcon from "@mui/icons-material/Brightness2Outlined";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import useUserSettingsStore from "../../stores/userSettingsStore";

const ProfileIconDrawer = ({ open, onClose, profilePicture }) => {
    const navigate = useNavigate();
    const { theme, toggleTheme } = useUserSettingsStore();

    const isDesktop = useMediaQuery("(min-width: 600px)");
    const drawerWidth = isDesktop ? 350 : "100%";

    const { authenticatedUser, setAuthenticatedUser } = useAuth();

    const handleUserPagesClick = (item) => {
        // Use the displayName to construct the route

        if (authenticatedUser) {
            switch (item) {
                case "Decks":
                    navigate(`/user/${authenticatedUser.displayName}/decks`);
                    break;
                case "Profile":
                    navigate(`/user/${authenticatedUser.displayName}`);
                    break;
                case "Collection":
                    navigate(
                        `/user/${authenticatedUser.displayName}/collection`
                    );
                    break;
                case "Settings":
                    navigate(`/user/${authenticatedUser.displayName}/settings`);
                    break;
                default:
                    return;
            }
        }
        onClose();
    };

    const handleLogOut = () => {
        Cookies.remove("accessToken");
        setAuthenticatedUser(null);
        window.location.reload(); // need to reload page to reinitialize auth token to apollo client
    };

    return (
        <>
            <Drawer anchor="right" open={open} onClose={onClose}>
                <Box
                    width={drawerWidth}
                    component="div"
                    sx={{
                        overflow: "auto",
                        p: 3,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ display: "flex" }}>
                        <img
                            src={profilePicture}
                            alt="Profile"
                            style={{
                                borderRadius: "50%",
                                width: "40px",
                            }}
                        />
                    </Box>
                    <Typography variant="body1" sx={{ ml: 1 }} color="text">
                        {authenticatedUser.displayName}
                    </Typography>
                    <Box flexGrow={1} />
                    <Button
                        sx={{ width: "20px" }}
                        color="text"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </Button>
                </Box>
                <Divider />
                <Button
                    color="text"
                    sx={{
                        textAlign: "left",
                        justifyContent: "flex-start",
                        m: 2,
                        textTransform: "none",
                        fontSize: "15px",
                        // color: "black",
                    }}
                    startIcon={
                        <AiOutlineUser
                            style={{ marginRight: "10px", fontSize: "25px" }}
                        />
                    }
                    onClick={() => handleUserPagesClick("Profile")}
                >
                    <Typography>My Profile</Typography>
                </Button>
                <Button
                    color="text"
                    sx={{
                        textAlign: "left",
                        justifyContent: "flex-start",
                        ml: 2,
                        mb: 2,
                        textTransform: "none",
                        fontSize: "15px",
                    }}
                    startIcon={
                        <LayersOutlinedIcon
                            style={{ marginRight: "10px", fontSize: "25px" }}
                        />
                    }
                    onClick={() => handleUserPagesClick("Decks")}
                >
                    My Decks
                </Button>
                <Button
                    color="text"
                    sx={{
                        textAlign: "left",
                        justifyContent: "flex-start",
                        ml: 2,
                        mb: 2,
                        textTransform: "none",
                        fontSize: "15px",
                    }}
                    startIcon={
                        <ViewModuleOutlinedIcon
                            style={{ marginRight: "10px", fontSize: "25px" }}
                        />
                    }
                    onClick={() => handleUserPagesClick("Collection")}
                >
                    My Collection
                </Button>
                <Divider />
                {/* TBD */}
                {/* <Button
                    color="text"
                    sx={{
                        textAlign: "left",
                        justifyContent: "flex-start",
                        m: 2,
                        textTransform: "none",
                        fontSize: "15px",
                    }}
                    startIcon={
                        <SettingsOutlinedIcon
                            style={{ marginRight: "10px", fontSize: "25px" }}
                        />
                    }
                    onClick={() => handleUserPagesClick("Settings")}
                >
                    Settings
                </Button> */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                        color="text"
                        disableRipple
                        sx={{
                            textAlign: "left",
                            justifyContent: "flex-start",
                            mt: 2,
                            ml: 2,
                            mb: 2,
                            textTransform: "none",
                            fontSize: "15px",
                            cursor: "default",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }}
                        startIcon={
                            theme === "light" ? (
                                <Brightness5OutlinedIcon
                                    style={{
                                        marginRight: "10px",
                                        fontSize: "25px",
                                    }}
                                />
                            ) : (
                                <Brightness2OutlinedIcon
                                    style={{
                                        marginRight: "10px",
                                        fontSize: "25px",
                                    }}
                                />
                            )
                        }
                    >
                        Dark Mode
                    </Button>
                    <Switch
                        checked={theme === "dark"}
                        onChange={() => toggleTheme()}
                        inputProps={{ "aria-label": "toggle theme" }}
                    />
                </Box>
                <Divider />
                <Button
                    color="text"
                    sx={{
                        textAlign: "left",
                        justifyContent: "flex-start",
                        m: 2,
                        textTransform: "none",
                        fontSize: "15px",
                    }}
                    startIcon={
                        <LogoutOutlinedIcon
                            style={{ marginRight: "10px", fontSize: "25px" }}
                        />
                    }
                    onClick={handleLogOut}
                >
                    Log Out
                </Button>
            </Drawer>
        </>
    );
};

export default ProfileIconDrawer;
