import { useLazyQuery, useQuery } from "@apollo/client";
import cardQueries from "../graphql/CardQueries";

const useCardService = () => {
    const useFindCardByUuid = (uuid) => {
        const {
            data: dataFindCardByUuid,
            loading: loadingFindCardByUuid,
            error: errorFindCardByUuid,
            refetch: refetchFindCardByUuid,
        } = useQuery(cardQueries.FIND_CARD_BY_UUID, {
            variables: {
                uuid: uuid || "",
            },
        });

        return {
            dataFindCardByUuid,
            loadingFindCardByUuid,
            errorFindCardByUuid,
            refetchFindCardByUuid,
        };
    };

    const useFindCardsAlike = () => {
        const [
            getCards,
            {
                data: dataFindCardsAlike,
                loading: loadingFindCardsAlike,
                error: errorFindCardsAlike,
            },
        ] = useLazyQuery(cardQueries.FIND_CARDS_ALIKE);

        return {
            getCards,
            dataFindCardsAlike,
            loadingFindCardsAlike,
            errorFindCardsAlike,
        };
    };

    return { useFindCardByUuid, useFindCardsAlike };
};

export default useCardService;
