import React from "react";
import { Box } from "@mui/material";

const TransparentCard = ({ children, style }) => (
    <Box
        style={{
            textAlign: "center",
            color: "#224",
            border: "1px solid rgba(255, 255, 255, .25)",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            boxShadow: "0 0 10px 1px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(5px)",
            width: "100%",
            ...style,
        }}
    >
        {children}
    </Box>
);

export default TransparentCard;
