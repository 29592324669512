import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
} from "@mui/material";
import useAuthenticationService from "../../hooks/useAuthenticationService";
import SnackbarMessage from "../../components/SnackbarMessage";

const LoginModal = ({ open, onClose, showSignUp }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [validEmail, setValidEmail] = useState(true);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const { login } = useAuthenticationService();

    const resetState = () => {
        setEmail("");
        setPassword("");
        setErrorMessage("");
        setSuccessMessage("");
        setValidEmail(true);
    };

    const validateEmail = (email) => {
        // Simple email format validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleLogin = async () => {
        // Validate email format
        if (!validateEmail(email) || !email.trim()) {
            setValidEmail(false);
            return;
        }

        if (!password.trim()) {
            // Password is empty
            setErrorMessage("Please enter your password.");
            return;
        }

        const result = await login(email, password);

        if (result.success) {
            setSuccessMessage(result.message);
            setShowSuccessSnackbar(true);

            setTimeout(() => {
                resetState();
                onClose();
                window.location.reload(); // need to reload page to reinitialize auth token to apollo client
            }, 3000);
        } else {
            setShowErrorSnackbar(true);
            setErrorMessage(result.message);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <form
                onSubmit={handleLogin}
                onKeyDown={(e) => e.key === "Enter" && handleLogin()}
            >
                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setValidEmail(true);
                        }}
                        helperText={!validEmail && "Invalid email"}
                        error={!validEmail}
                        required
                    />

                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Typography variant="body2" style={{ marginTop: 10 }}>
                        New to Spell Sheets?{" "}
                        <span
                            style={{ color: "#4077de", cursor: "pointer" }}
                            onClick={showSignUp}
                        >
                            Sign Up
                        </span>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleLogin}
                        variant="contained"
                        color="primary"
                    >
                        Log In
                    </Button>
                </DialogActions>
            </form>

            <SnackbarMessage
                open={showErrorSnackbar || showSuccessSnackbar}
                onClose={() => {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
                message={showErrorSnackbar ? errorMessage : successMessage}
                severity={showErrorSnackbar ? "error" : "success"}
            />
        </Dialog>
    );
};

export default LoginModal;
