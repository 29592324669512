// settingsStore.js
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useDeckSettingsStore = create(
    persist(
        (set) => ({
            displayOwnershipText: true,
            setDisplayOwnershipText: (value) => set({ displayOwnershipText: value }),
        }),
        { name: 'deck-settings-store' }
    )
);

export default useDeckSettingsStore;
