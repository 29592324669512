export const formatOptions = [
    { id: 1, value: "none", label: "None", requiresCommander: false },
    { id: 2, value: "alchemy", label: "Alchemy", requiresCommander: false },
    { id: 3, value: "brawl", label: "Brawl", requiresCommander: true },
    {
        id: 4,
        value: "commander",
        label: "Commander",
        requiresCommander: true,
    },
    { id: 5, value: "duel", label: "Duel", requiresCommander: true },
    {
        id: 6,
        value: "explorer",
        label: "Explorer",
        requiresCommander: false,
    },
    {
        id: 8,
        value: "gladiator",
        label: "Gladiator",
        requiresCommander: false,
    },
    {
        id: 9,
        value: "historic",
        label: "Historic",
        requiresCommander: false,
    },
    {
        id: 10,
        value: "historicbrawl",
        label: "Historic Brawl",
        requiresCommander: true,
    },
    { id: 11, value: "legacy", label: "Legacy", requiresCommander: false },
    { id: 12, value: "modern", label: "Modern", requiresCommander: false },
    {
        id: 13,
        value: "oathbreaker",
        label: "Oathbreaker",
        requiresCommander: false,
    },
    {
        id: 14,
        value: "oldschool",
        label: "Oldschool",
        requiresCommander: false,
    },
    { id: 15, value: "pauper", label: "Pauper", requiresCommander: false },
    {
        id: 16,
        value: "paupercommander",
        label: "Pauper Commander",
        requiresCommander: true,
    },
    { id: 17, value: "penny", label: "Penny", requiresCommander: false },
    {
        id: 18,
        value: "pioneer",
        label: "Pioneer",
        requiresCommander: false,
    },
    { id: 19, value: "predh", label: "Predh", requiresCommander: true },
    {
        id: 20,
        value: "premodern",
        label: "Premodern",
        requiresCommander: false,
    },
    {
        id: 21,
        value: "standard",
        label: "Standard",
        requiresCommander: false,
    },
    {
        id: 22,
        value: "vintage",
        label: "Vintage",
        requiresCommander: false,
    },
];