import React from "react";
import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Explore = () => {
    const navigate = useNavigate();

    return (
        <Box style={{ textAlign: "center", marginTop: "50px", height: "83vh" }}>
            <h1>Coming Soon!</h1>
            <Button
                onClick={() => navigate(`/`)}
                sx={{ textAlign: "center" }}
                variant="contained"
            >
                Go Back Home
            </Button>
        </Box>
    );
};

export default Explore;
