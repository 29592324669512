import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Container,
    Typography,
    Box,
    Button,
    TextField,
    Grid,
    Divider,
    InputAdornment,
    IconButton,
    CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NewDeckModal from "./NewDeckModal";
import DeckList from "./DeckList";
import DeckGrid from "./DeckGrid";
import useDeckService from "../../hooks/useDeckService";
import useUserService from "../../hooks/useUserService";
import { useAuth } from "../../context/AuthProvider";
import SnackbarMessage from "../../components/SnackbarMessage";
import NoUserFound from "./NoUserFound";
import NoDecks from "./NoDecks";

const UserDecks = () => {
    const navigate = useNavigate();

    const { displayName } = useParams();

    const [decks, setDecks] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [isGridView, setIsGridView] = useState(false);
    const { authenticatedUser } = useAuth();
    const [validUser, setValidUser] = useState();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [deletionSuccess, setDeletionSuccess] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);

    const { addDeck, useDecksByDisplayName } = useDeckService();
    const { refetchUserDecks } = useDecksByDisplayName(displayName);
    const { isValidDisplayName, loadingValidDisplayName } =
        useUserService(displayName);

    // useEffect to update state after creating a deck
    useEffect(() => {
        const verifyActiveUser = async () => {
            // make request to see if displayName exists
            try {
                const res = await isValidDisplayName;
                setValidUser(res.validDisplayName);
                return res.validDisplayName;
            } catch (error) {
                setValidUser(false);
                return false;
            }
        };

        const fetchData = async () => {
            try {
                // Refetch the decks to update the state
                const refetchResult = await refetchUserDecks();
                const newDecks =
                    refetchResult.data?.getDecksByDisplayName || [];
                setDecks(newDecks);
                setDeletionSuccess(false);
            } catch (error) {
                console.error("Error refetching decks:", error.message);
            }
        };

        if (verifyActiveUser()) {
            fetchData();
        }
        setLoadingPage(false);
    }, [
        authenticatedUser,
        refetchUserDecks,
        setDecks,
        deletionSuccess,
        displayName,
        isValidDisplayName,
        loadingValidDisplayName,
    ]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleCreateDeck = async (deck) => {
        // console.log("deck: ", deck);
        setLoadingCreate(true);
        addDeck(deck)
            .then((newDeck) => {
                const newDeckSlug = newDeck?.slug;
                setTimeout(() => {
                    // Navigate to the new page
                    navigate(`/decks/${newDeckSlug}`);
                }, 2000);
                setShowSuccess(true);
                setLoadingCreate(false);
            })
            .catch((err) => {
                setLoadingCreate(false);
                setShowError(true);
            });
    };

    const toggleView = () => {
        setIsGridView(!isGridView);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter data based on search query
    const filteredData = decks.filter((deck) =>
        deck.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const userCanEdit = () => {
        if (
            authenticatedUser &&
            authenticatedUser.displayName === displayName
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            {loadingPage ? (
                <Box sx={{ textAlign: "center", padding: "20px" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container sx={{ height: "85vh" }}>
                    {loadingValidDisplayName ? (
                        <div style={{ textAlign: "center", marginTop: "50px" }}>
                            <CircularProgress />
                        </div>
                    ) : validUser ? (
                        <>
                            <Box sx={{ mt: 4 }}>
                                <Typography variant="h4">
                                    {displayName} Decks ({decks.length})
                                </Typography>
                            </Box>

                            <Grid container spacing={2} sx={{ mt: 2, mb: 3 }}>
                                {userCanEdit() ? (
                                    <Grid item xs={4}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleOpenModal}
                                        >
                                            New Deck
                                        </Button>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                <Grid
                                    item
                                    xs={userCanEdit() ? 8 : 12}
                                    container
                                    justifyContent="flex-end"
                                >
                                    {/* <IconButton
                                        onClick={toggleView}
                                        sx={{
                                            border: "1px solid grey",
                                            borderRadius: "50%",
                                            padding: "10px",
                                        }}
                                    >
                                        {isGridView ? (
                                            <GridOnIcon />
                                        ) : (
                                            <ListIcon />
                                        )}
                                    </IconButton>
                                    <FilterDecksButton
                                        decks={decks}
                                        setDecks={setDecks}
                                    /> */}
                                    <TextField
                                        placeholder="Search your deck"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleSearchChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ marginLeft: 2 }}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            {decks.length > 0 ? (
                                <>
                                    {isGridView ? (
                                        <DeckGrid
                                            decks={filteredData}
                                            setDeletionSuccess={
                                                setDeletionSuccess
                                            }
                                            editable={userCanEdit()}
                                        />
                                    ) : (
                                        <DeckList
                                            decks={filteredData}
                                            setDeletionSuccess={
                                                setDeletionSuccess
                                            }
                                            editable={userCanEdit()}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <NoDecks
                                        userCanEdit={userCanEdit}
                                        handleOpenModal={handleOpenModal}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <NoUserFound displayName={displayName} />
                    )}

                    <NewDeckModal
                        open={openModal}
                        onClose={handleCloseModal}
                        onCreate={handleCreateDeck}
                        loadingCreate={loadingCreate}
                    />

                    <SnackbarMessage
                        open={showError || showSuccess}
                        onClose={() => {
                            setShowError(false);
                            setShowSuccess(false);
                        }}
                        message={
                            showError
                                ? "Error creating deck"
                                : "Deck created successfully"
                        }
                        severity={showError ? "error" : "success"}
                    />
                </Container>
            )}
        </>
    );
};

export default UserDecks;
