import React from "react";

const Settings = () => {
    return (
        <>
            <h1>Settings Page</h1>
        </>
    );
};

export default Settings;
