import { useMutation } from "@apollo/client";
import randomQueries from "../graphql/RandomQueries";

const useRandomService = () => {
    const [sendFeedbackMutation] = useMutation(randomQueries.SEND_FEEDBACK);

    const sendFeedback = async (feedback) => {
        try {
            const { data } = await sendFeedbackMutation({
                variables: {
                    feedback: feedback,
                },
            });

            // Assuming the mutation returns the newly added deck
            return data.sendFeedback;
        } catch (error) {
            console.log("Error adding deck: ", error.message);
            throw error;
        }
    };

    return { sendFeedback };
};

export default useRandomService;
