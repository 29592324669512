import { gql } from "@apollo/client";

const cardQueries = {
    FIND_CARD_BY_UUID: gql`
        query FindCardByUuid($uuid: String!) {
            findCardByUuid(uuid: $uuid) {
                id
                name
                normal
                typeLine
                uuid
                oracleText
                flavorText
                manaCost
                rulingsUri
                usd
                alchemy
                brawl
                commander
                duel
                explorer
                future
                gladiator
                historic
                historicBrawl
                legacy
                modern
                oathbreaker
                oldSchool
                pauper
                pauperCommander
                penny
                pioneer
                predh
                premodern
                standard
                vintage
                cardhoarder
                cardmarket
                tcgplayer
                edhrec
            }
        }
    `,

    FIND_CARD_BY_NAME: gql`
        query FindCardByName($cardName: String!) {
            findCardByName(cardName: $cardName) {
                id
                name
                manaCost
                colorIdentity
                normal
            }
        }
    `,

    FIND_CARDS_ALIKE: gql`
        query FindCardsAlike($cardName: String!, $count: Int!) {
            findCardsAlike(cardName: $cardName, count: $count) {
                id
                name
                normal
                typeLine
                uuid
                oracleText
                flavorText
                manaCost
                rulingsUri
                usd
                alchemy
                brawl
                commander
                duel
                explorer
                future
                gladiator
                historic
                historicBrawl
                legacy
                modern
                oathbreaker
                oldSchool
                pauper
                pauperCommander
                penny
                pioneer
                predh
                premodern
                standard
                vintage
                cardhoarder
                cardmarket
                tcgplayer
                edhrec
            }
        }
    `,
};

export default cardQueries;
