// Inside DeckTiles.jsx
import React, { useEffect, useState } from "react";

import Tile from "./Tile";
import { Box, Grid } from "@mui/material";

const DeckTiles = ({ deckData, onDeckUpdate, ownership }) => {
    const COLUMN_COUNT = 4;
    const [columns, setColumns] = useState([]);

    const calculateTileHeight = (cardCount) => {
        // rough estimate based on number of unique cards (approx 25 px : may change in time) (30 px is for tile header)
        return cardCount * 25 + 30;
    };

    useEffect(() => {
        const groupedColumns = Array.from({ length: COLUMN_COUNT }, () => []);

        if (deckData?.tiles) {
            deckData.tiles.forEach((tile, idx) => {
                // Assume tileHeight is dynamic and based on content
                const tileHeight = calculateTileHeight(tile.cards.length);

                // Find the column with the minimum total height
                const columnIndex = groupedColumns.reduce(
                    (minIndex, column, index, columns) =>
                        column.reduce((sum, t) => sum + t.height, 0) <
                        columns[minIndex].reduce((sum, t) => sum + t.height, 0)
                            ? index
                            : minIndex,
                    0
                );

                groupedColumns[columnIndex].push({
                    ...tile,
                    height: tileHeight,
                });
            });
        }

        setColumns(groupedColumns);
    }, [deckData.tiles]);

    return (
        <Box id="deckTiles" sx={{ margin: { xs: 0, md: 5 } }}>
            <Grid container spacing={1}>
                {columns.map((column, colIdx) => (
                    // <Grid key={colIdx} item xs={12 / COLUMN_COUNT} md={3}>
                    <Grid key={colIdx} item xs={12} md={3}>
                        {column.map((tile, tileIdx) => (
                            <Tile
                                key={tileIdx}
                                deckId={deckData.id}
                                tile={tile}
                                onDeckUpdate={onDeckUpdate}
                                ownership={ownership}
                            />
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default DeckTiles;
