import { Modal, Box, Backdrop } from "@mui/material";
import React from "react";

const CustomModal = ({ open, onClose, children, style }) => {
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "1px solid #9f9f9f",
        borderRadius: "5px",
        boxShadow: 1,
        p: 2,
        ...style,
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        sx: {
                            // backgroundColor: "rgba(255, 255, 255, 0.6)",
                        },
                    },
                }}
            >
                <Box sx={modalStyle}>{children}</Box>
            </Modal>
        </>
    );
};

export default CustomModal;
