import { useMutation, useQuery } from "@apollo/client";
import deckQueries from "../graphql/DeckQueries";

const useDeckService = () => {
    const [addDeckMutation] = useMutation(deckQueries.ADD_DECK);
    const [deleteDeckMutation] = useMutation(deckQueries.DELETE_DECK);
    const [addCardToDeckMutation] = useMutation(deckQueries.ADD_CARD_TO_DECK);
    const [updateDeckCardOwnershipMutation] = useMutation(
        deckQueries.UPDATE_DECK_CARD_OWNERSHIP
    );
    const [removeCardFromDeckMutation] = useMutation(
        deckQueries.REMOVE_CARD_FROM_DECK
    );
    const [updateDeckDetailsMutation] = useMutation(
        deckQueries.UPDATE_DECK_DETAILS
    );
    const [updateDeckBannerMutation] = useMutation(
        deckQueries.UPDATE_DECK_BANNER
    );

    const [updateCardTileMutation] = useMutation(deckQueries.UPDATE_CARD_TILE);

    const useDecksByDisplayName = (displayName) => {
        const { refetch: refetchUserDecks } = useQuery(
            deckQueries.GET_DECKS_BY_DISPLAYNAME,
            {
                variables: {
                    displayName: displayName || "",
                },
            }
        );

        return {
            refetchUserDecks,
        };
    };

    const useDeckBySlug = (slug) => {
        const {
            data: dataDeckBySlug,
            error: errorDeckBySlug,
            refetch: refetchDeckBySlug,
        } = useQuery(deckQueries.GET_DECK_BY_SLUG, {
            variables: {
                slug: slug || "",
            },
        });

        return {
            dataDeckBySlug,
            errorDeckBySlug,
            refetchDeckBySlug,
        };
    };

    const useGetDeckOwnership = (slug) => {
        const {
            data: dataGetDeckOwnership,
            error: errorGetDeckOwnership,
            refetch: refetchGetDeckOwnership,
        } = useQuery(deckQueries.GET_DECK_OWNERSHIP, {
            variables: {
                slug: slug || "",
            },
        });

        return {
            dataGetDeckOwnership,
            errorGetDeckOwnership,
            refetchGetDeckOwnership,
        };
    };

    const addDeck = async (deck) => {
        try {
            const { data } = await addDeckMutation({
                variables: {
                    name: deck.name,
                    format: deck.format,
                    commander: deck.commander,
                    privacy: deck.privacy,
                    deckImport: deck.existingDeckText,
                    url: deck.url,
                },
            });

            // Assuming the mutation returns the newly added deck
            return data.addDeck;
        } catch (error) {
            console.log("Error adding deck: ", error.message);
            throw error;
        }
    };

    const deleteDeck = async (deckId) => {
        try {
            const { data } = await deleteDeckMutation({
                variables: {
                    deckId: deckId,
                },
            });
            console.log("Delete Deck Response: ", data);
            return data.deleteDeck; // Assuming deleteDeck returns a Boolean
        } catch (error) {
            console.log("Error deleting deck: ", error.message);
            throw error;
        }
    };

    const addCardToDeck = async (deckId, cardUid, quantity, own) => {
        try {
            const { data } = await addCardToDeckMutation({
                variables: {
                    deckId: deckId,
                    cardUid: cardUid,
                    quantity: quantity,
                    own: own,
                },
            });

            // Assuming the mutation returns the updated deck data
            const updatedDeckData = data.addCardToDeck;

            return updatedDeckData;
        } catch (error) {
            console.log("Error adding card to deck: ", error.message);
            throw error;
        }
    };

    const removeCardFromDeck = async (deckId, cardUid, quantity) => {
        try {
            const { data } = await removeCardFromDeckMutation({
                variables: {
                    deckId: deckId,
                    cardUid: cardUid,
                    quantity: quantity,
                },
            });

            const updatedDeckData = data.removeCardFromDeck;

            return updatedDeckData;
        } catch (error) {
            console.log("Error deleting card from deck: ", error.message);
            throw error;
        }
    };

    const updateDeckDetails = async (
        deckId,
        name,
        description,
        format,
        privacy,
        tags
    ) => {
        try {
            const { data } = await updateDeckDetailsMutation({
                variables: {
                    deckId: deckId,
                    name: name,
                    description: description,
                    format: format,
                    privacy: privacy,
                    tags,
                },
            });
            const updatedDeckData = data.updateDeckDetails;

            return updatedDeckData;
        } catch (err) {
            console.log("Error updating deck details: ", err.message);
            throw err;
        }
    };

    const updateDeckCardOwnership = async (deckId, cardUid, quantity) => {
        try {
            const { data } = await updateDeckCardOwnershipMutation({
                variables: {
                    deckId: deckId,
                    cardUid: cardUid,
                    quantity: quantity,
                },
            });
            const updatedDeckData = data.updateDeckCardOwnership;

            return updatedDeckData;
        } catch (err) {
            console.log("error updating deck card ownership: ", err.message);
            throw err;
        }
    };

    const updateDeckBanner = async (deckId, banner) => {
        try {
            const { data } = await updateDeckBannerMutation({
                variables: {
                    deckId: deckId,
                    banner: banner,
                },
            });
            const updatedDeckData = data.updateDeckBanner;

            return updatedDeckData;
        } catch (err) {
            console.log("deck banner update failed: ", err.message);
            throw err;
        }
    };

    const updateCardTile = async (deckId, cardUid, tileName, tileIcon) => {
        try {
            const { data } = await updateCardTileMutation({
                variables: {
                    deckId: deckId,
                    cardUid: cardUid,
                    tileName: tileName,
                    tileIcon: tileIcon,
                },
            });

            const updatedDeckData = data.updateCardTile;

            return updatedDeckData;
        } catch (err) {
            console.log("card tile failed to update: ", err.message);
            throw err;
        }
    };

    return {
        addDeck,
        deleteDeck,
        useDecksByDisplayName,
        useDeckBySlug,
        addCardToDeck,
        removeCardFromDeck,
        useGetDeckOwnership,
        updateDeckDetails,
        updateDeckCardOwnership,
        updateDeckBanner,
        updateCardTile,
    };
};

export default useDeckService;
