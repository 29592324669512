import {
    Typography,
    useMediaQuery,
    Divider,
    TextField,
    Box,
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import CustomModal from "../../components/CustomModal";
import { formatForExport } from "../../util/deckUtil";
import SnackbarMessage from "../../components/SnackbarMessage";

const ExportModal = ({ open, onClose, cards }) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");

    const [selectedFormat, setSelectedFormat] = useState("1");
    const [formattedCards, setFormattedCards] = useState("");
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleFormatChange = (event) => {
        const format = event.target.value;
        setSelectedFormat(format);

        switch (format) {
            case "1":
                setFormattedCards(formatForExport(cards, "1"));
                break;
            case "2":
                setFormattedCards(formatForExport(cards, "2"));
                break;
            case "3":
                setFormattedCards(formatForExport(cards, "3"));
                break;
            default:
                setFormattedCards(formatForExport(cards, "1"));
        }
    };

    const handleExport = () => {
        // Copy the formattedCards text to the clipboard
        navigator.clipboard
            .writeText(formattedCards)
            .then(() => {
                setShowSuccessSnackbar(true);
                setSuccessMessage("Export copied to clipboard.");
            })
            .catch((error) => {
                setShowErrorSnackbar(true);
                setErrorMessage(error);
            });
    };

    useEffect(() => {
        setFormattedCards(formatForExport(cards, selectedFormat));
    }, [cards, selectedFormat]);

    return (
        <>
            <CustomModal
                open={open}
                onClose={onClose}
                style={{
                    textAlign: "left",
                    width: isDesktop ? "550px" : "90%",
                    height: isDesktop ? "500px" : "60%",
                }}
            >
                <Typography variant="h5">Export</Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <InputLabel variant="standard">Format Options</InputLabel>
                <FormControl style={{ width: "100%" }}>
                    <Select
                        value={selectedFormat}
                        onChange={handleFormatChange}
                    >
                        <MenuItem value="1">1 Card Name</MenuItem>
                        <MenuItem value="2">1x Card Name</MenuItem>
                        <MenuItem value="3">1 Card Name (Set Name)</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    rows={10}
                    multiline
                    value={formattedCards} // Set value to the formattedCards state
                    placeholder="Paste your deck list here..."
                    style={{
                        width: "100%",
                        resize: "vertical",
                        marginTop: "10px",
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "right",
                        mt: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClose}
                        sx={{ mr: 2, width: "100px" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleExport}
                        sx={{ width: "100px" }}
                    >
                        Copy
                    </Button>
                </Box>
            </CustomModal>
            <SnackbarMessage
                open={showErrorSnackbar || showSuccessSnackbar}
                onClose={() => {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
                message={showErrorSnackbar ? errorMessage : successMessage}
                severity={showErrorSnackbar ? "error" : "success"}
            />
        </>
    );
};

export default ExportModal;
