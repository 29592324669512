import React from "react";
import { Grid, Skeleton, Typography, Button } from "@mui/material";
import Spellbook from "../../static/spellbook.png";

const NoDecks = ({ userCanEdit, handleOpenModal }) => {
    return (
        <Grid container>
            {[...Array(6)].map((_, index) => (
                <Grid item xs={4} key={index}>
                    <Skeleton
                        variant="rectangular"
                        height={150}
                        sx={{
                            m: 2,
                            backgroundColor: "#e8e8e8",
                        }}
                    />
                </Grid>
            ))}
            <div
                style={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                <img
                    src={Spellbook}
                    style={{ width: "100px" }}
                    alt="spellbook-icon"
                />
                <Typography
                    variant="body1"
                    color="text.primary"
                    style={{
                        fontSize: "20px",
                        fontWeight: "600",
                    }}
                >
                    Your spell book is empty, create your first deck now!
                </Typography>
                {userCanEdit() ? (
                    <Button
                        variant="contained"
                        sx={{ mt: 2 }}
                        onClick={handleOpenModal}
                    >
                        Create New Deck
                    </Button>
                ) : (
                    <></>
                )}
            </div>
        </Grid>
    );
};

export default NoDecks;
