import React, { useState, useEffect } from "react";
import {
    Drawer,
    Typography,
    useMediaQuery,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Divider,
    Paper,
    Chip,
} from "@mui/material";
import { formatOptions } from "../../static/formats.js";
import useDeckService from "../../hooks/useDeckService.jsx";
import SnackbarMessage from "../../components/SnackbarMessage.jsx";

import default1 from "../../static/banners/land.jpg";
import default2 from "../../static/banners/cave.jpg";
import default3 from "../../static/banners/forestMountains.jpg";
import default4 from "../../static/banners/forestShrine.jpg";
import default5 from "../../static/banners/heaven.jpg";
import default6 from "../../static/banners/oceanVolcano.jpg";
import default7 from "../../static/banners/portTown.jpg";
import default8 from "../../static/banners/swamp.jpg";
import default9 from "../../static/banners/volcano.jpg";
import default10 from "../../static/banners/waterCity.jpg";

const bannerImages = [
    default1,
    default2,
    default3,
    default4,
    default5,
    default6,
    default7,
    default8,
    default9,
    default10,
];

const EditDeckDetailsDrawer = ({
    deckData,
    open,
    onClose,
    onDeckEditComplete,
}) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const drawerWidth = isDesktop ? 500 : "100%";
    const [validName, setValidName] = useState(true);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [selectedImage, setSelectedImage] = useState(deckData?.banner);
    const { updateDeckDetails, updateDeckBanner } = useDeckService();

    const [formState, setFormState] = useState({
        name: deckData?.name,
        description: deckData?.description,
        format: deckData?.format,
        privacy: deckData?.privacy,
        tags: deckData?.tags,
    });

    useEffect(() => {
        // update formState when deckData changes (asynchronous fix)
        setFormState({
            name: deckData?.name || "",
            description: deckData?.description || "",
            format: deckData?.format || "",
            privacy: deckData?.privacy || "",
            tags: deckData?.tags || [],
        });
    }, [deckData]);

    const handleInputChange = (field, value) => {
        // Ensure that inputValue is always a string
        const inputValue = typeof value === "string" ? value : value.name || "";

        setFormState((prevState) => ({
            ...prevState,
            [field]: inputValue,
        }));
    };

    const handleDeckDetailsUpdate = async () => {
        try {
            const updatedDeckData = await updateDeckDetails(
                deckData.id,
                formState.name,
                formState.description,
                formState.format,
                formState.privacy,
                formState.tags
            );
            onDeckEditComplete(updatedDeckData);
            setSuccessMessage("Deck updated successfully.");
            setShowSuccessSnackbar(true);
        } catch (err) {
            console.log("err: ", err);
            setErrorMessage("Error updating deck details.");
            setShowErrorSnackbar(true);
        }
    };

    const handleBannerImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleDeckBannerImageSave = async () => {
        console.log("saving banner image :) : ", selectedImage);
        try {
            const updatedDeckData = await updateDeckBanner(
                deckData.id,
                selectedImage
            );
            onDeckEditComplete(updatedDeckData);
            setSuccessMessage("Deck updated successfully.");
            setShowSuccessSnackbar(true);
        } catch (err) {
            console.log("err: ", err);
            setErrorMessage("Error updating deck details.");
            setShowErrorSnackbar(true);
        }
    };

    const handleTagDelete = (tagToDelete) => {
        setFormState((prevState) => ({
            ...prevState,
            tags: prevState.tags.filter((tag) => tag !== tagToDelete),
        }));
    };

    const handleTagInputChange = (event) => {
        const newTag = event.target.value;
        if (newTag.trim() !== "") {
            if (formState.tags.length < 5) {
                setFormState((prevState) => ({
                    ...prevState,
                    tags: [...prevState.tags, newTag.trim()],
                }));
            } else {
                setErrorMessage("Maximum 5 tags allowed per deck.");
                setShowErrorSnackbar(true);
            }
            event.target.value = "";
        }
    };

    return (
        <>
            <Drawer anchor="left" open={open} onClose={onClose}>
                <Box
                    width={drawerWidth}
                    component="div"
                    sx={{ overflow: "auto", p: 3 }}
                >
                    <form onSubmit={handleDeckDetailsUpdate}>
                        <Typography variant="h5">Edit Deck Details</Typography>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            value={formState.name}
                            onChange={(e) =>
                                handleInputChange("name", e.target.value)
                            }
                            sx={{ mt: 2 }}
                            error={!validName}
                            helperText={!validName && "Name required"}
                            required
                        />
                        <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            value={formState.description}
                            onChange={(e) =>
                                handleInputChange("description", e.target.value)
                            }
                            sx={{ mb: 2, mt: 2 }}
                        />
                        <FormControl fullWidth>
                            <InputLabel id="format-label">Format</InputLabel>
                            <Select
                                labelId="format-label"
                                label="Format"
                                variant="outlined"
                                fullWidth
                                value={formState.format}
                                required
                                onChange={(e) =>
                                    handleInputChange("format", e.target.value)
                                }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getcontentanchorel: null,
                                    PaperProps: {
                                        style: {
                                            maxHeight: 250, // Set your desired max height
                                        },
                                    },
                                }}
                                sx={{ marginBottom: 2 }}
                            >
                                {formatOptions.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant="subtitle2">Privacy</Typography>
                        <RadioGroup
                            aria-label="privacy"
                            name="privacy"
                            value={formState.privacy}
                            onChange={(e) =>
                                handleInputChange("privacy", e.target.value)
                            }
                            row
                        >
                            <FormControlLabel
                                value="public"
                                control={<Radio />}
                                label="Public"
                            />
                            <FormControlLabel
                                value="private"
                                control={<Radio />}
                                label="Private"
                            />
                        </RadioGroup>

                        {/* <Typography variant="subtitle2">Tags</Typography> */}
                        <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1 }}>
                            <TextField
                                label="Add Tag"
                                variant="outlined"
                                size="small"
                                placeholder="Add tags that describe your deck (Max: 5)"
                                fullWidth
                                autoComplete="off"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleTagInputChange(e);
                                    }
                                }}
                            />
                            {formState.tags.map((tag, index) => (
                                <Chip
                                    sx={{ mt: 1 }}
                                    key={index}
                                    label={tag}
                                    onDelete={() => handleTagDelete(tag)}
                                />
                            ))}
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 2,
                            }}
                        >
                            <Button
                                onClick={onClose}
                                color="primary"
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => handleDeckDetailsUpdate()}
                                color="primary"
                                variant="contained"
                            >
                                Update
                            </Button>
                        </Box>
                    </form>
                    <Divider sx={{ mt: 3 }} />
                    <Typography variant="h5" sx={{ mt: 2 }}>
                        Choose a banner image
                    </Typography>
                    <Box sx={{ overflowX: "scroll" }}>
                        <Box sx={{ display: "flex" }}>
                            {bannerImages.map((image, index) => (
                                <Paper
                                    key={index}
                                    elevation={3}
                                    sx={{
                                        //width: "200px", // (You can remove fixed width if not necessary)
                                        height: 100,
                                        margin: 1,
                                        overflow: "hidden",
                                        borderRadius: 1,
                                        cursor: "pointer",
                                        boxShadow:
                                            selectedImage === image
                                                ? "0 0 3px 5px rgba(23, 164, 212, 0.5)"
                                                : "none",
                                        flexShrink: 0,
                                    }}
                                    onClick={() =>
                                        handleBannerImageClick(image)
                                    }
                                >
                                    <img
                                        src={image}
                                        alt={`Banner ${index + 1}`}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                </Paper>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 2,
                        }}
                    >
                        <Button
                            onClick={() => handleDeckBannerImageSave()}
                            color="primary"
                            variant="contained"
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
            </Drawer>
            <SnackbarMessage
                open={showErrorSnackbar || showSuccessSnackbar}
                onClose={() => {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
                message={showErrorSnackbar ? errorMessage : successMessage}
                severity={showErrorSnackbar ? "error" : "success"}
            />
        </>
    );
};

export default EditDeckDetailsDrawer;
