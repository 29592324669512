import React from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const SnackbarMessage = ({ open, onClose, message, severity }) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
            <SnackbarContent
                message={message}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                style={{
                    backgroundColor: severity === "success" ? "green" : "red",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            />
        </Snackbar>
    );
};

export default SnackbarMessage;
