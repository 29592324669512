import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomModal from "../../components/CustomModal";

const ConfirmProfilePicModal = ({ open, onClose, profilePicture, onSave }) => {
    return (
        <CustomModal open={open} onClose={onClose} style={{ width: "400px" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    m: 1,
                    alignItems: "center",
                }}
            >
                <Typography variant="h5">Confirm Profile Picture</Typography>
                <Box flexGrow={1} />
                <Button color="text" onClick={onClose}>
                    <CloseIcon />
                </Button>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                }}
            >
                <Avatar
                    src={profilePicture}
                    alt="Profile"
                    sx={{
                        width: 300,
                        height: 300,
                        border: "1px solid #616161",
                        // m: 3,
                        mt: 3,
                        ml: "auto",
                        mr: "auto",
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    m: 3,
                }}
            >
                <Button
                    variant="contained"
                    sx={{ ml: "auto", mr: "auto", width: "100px" }}
                    onClick={() => onSave(profilePicture)}
                >
                    Save
                </Button>
            </Box>
        </CustomModal>
    );
};

export default ConfirmProfilePicModal;
