export const getCurrentVersion = () => {
    return '0.2.0';
};

export const getStoredVersion = () => {
    return localStorage.getItem('appVersion');
};

export const setStoredVersion = (version) => {
    localStorage.setItem('appVersion', version);
};