import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import userQueries from "../graphql/UserQueries";

const useUserService = (displayName) => {
    // Valid DisplayName
    const { data: isValidDisplayName, loading: loadingValidDisplayName } =
        useQuery(userQueries.VALID_DISPLAYNAME, {
            variables: {
                displayName: displayName || "",
            },
        });

    // Upload Profile Picture
    const [uploadProfilePictureMutation] = useMutation(
        userQueries.UPLOAD_PROFILE_PICTURE
    );
    const uploadProfilePicture = async (profilePic) => {
        try {
            const { data } = await uploadProfilePictureMutation({
                variables: {
                    profilePicture: profilePic,
                },
            });

            return data.uploadProfilePicture;
        } catch (err) {
            console.log("error uploading pfp: ", err);
            throw err;
        }
    };

    // Get Profile Picture
    const {
        loading: loadUserProfilePicture,
        error: errorUserProfilePicture,
        data: userProfilePicture,
    } = useQuery(userQueries.GET_PROFILE_PICTURE, {
        variables: {
            displayName: displayName || "",
        },
    });

    // Get Users based on displayName
    const useFindUsersAlike = () => {
        const [
            getUsers,
            {
                data: dataFindUsersAlike,
                loadin: loadingFindUsersAlike,
                error: errorFindUsersAlike,
            },
        ] = useLazyQuery(userQueries.FIND_USERS_ALIKE);

        return {
            getUsers,
            dataFindUsersAlike,
            loadingFindUsersAlike,
            errorFindUsersAlike,
        };
    };

    return {
        isValidDisplayName,
        loadingValidDisplayName,
        uploadProfilePicture,
        loadUserProfilePicture,
        errorUserProfilePicture,
        userProfilePicture,
        useFindUsersAlike,
    };
};

export default useUserService;
