export const calculateTimeAgo = (lastUpdated) => {
    const today = new Date();
    const updatedDate = new Date(lastUpdated);
    const timeDifference = today - updatedDate;

    const minutesDifference = timeDifference / (1000 * 60);
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    if (minutesDifference < 1) {
        const seconds = Math.floor(timeDifference / 1000);
        return seconds === 1 ? `${seconds} second ago` : `${seconds} seconds ago`;
    } else if (hoursDifference < 1) {
        const minutes = Math.floor(minutesDifference);
        return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else if (daysDifference < 1) {
        const hours = Math.floor(hoursDifference);
        return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else {
        const days = Math.floor(daysDifference);
        return days === 1 ? `${days} day ago` : `${days} days ago`;
    }
};
