import React, { useState, useEffect } from "react";
import {
    Autocomplete,
    TextField,
    CircularProgress,
    IconButton,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLazyQuery } from "@apollo/client";
import cardQueries from "../../graphql/CardQueries";
import useDeckService from "../../hooks/useDeckService";
import { debounce } from "lodash";
import CardPreviewDrawer from "./CardPreviewDrawer";
import SnackbarMessage from "../../components/SnackbarMessage";

const CardAutocomplete = ({ deckId, onAddCardComplete, ownership }) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [hoveredOption, setHoveredOption] = useState(null);
    const [modalCard, setModalCard] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

    const { addCardToDeck } = useDeckService();

    const [fetchCards, { data }] = useLazyQuery(cardQueries.FIND_CARDS_ALIKE, {
        fetchPolicy: "no-cache", // Prevent caching for immediate updates
    });

    const debounceHandleInputChange = debounce((value) => {
        setLoading(true);
        fetchCards({ variables: { cardName: value, count: 15 } });
    }, 1000);

    const handleInputChange = (event, value, reason) => {
        if (reason === "input") {
            debounceHandleInputChange(value);
        }
    };

    useEffect(() => {
        if (data) {
            const newOptions = data.findCardsAlike || [];
            setOptions(newOptions);
            setLoading(false);
        }
    }, [data]);

    const getOptionLabel = (option, index) =>
        `${option.name} - ${option.typeLine} [${option.id}]`;

    const handleOptionMouseEnter = (option) => {
        setHoveredOption(option);
    };

    const handleOptionMouseLeave = () => {
        setHoveredOption(null);
    };

    const handleAddCardToDeck = async (option) => {
        try {
            const updatedDeckData = await addCardToDeck(deckId, option.uuid, 1);
            onAddCardComplete(updatedDeckData);
            setShowSuccessSnackbar(true);
        } catch {
            setShowErrorSnackbar(true);
        }
    };

    const openCardDetails = (option) => {
        setModalCard(option);
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setModalCard(null);
        setOpenModal(false);
    };

    return (
        <>
            <Autocomplete
                options={options}
                loading={loading}
                getOptionLabel={getOptionLabel}
                onInputChange={handleInputChange}
                renderInput={(params) => (
                    <>
                        <TextField
                            {...params}
                            label={
                                ownership
                                    ? "Find and add cards..."
                                    : "Find cards..."
                            }
                            variant="outlined"
                        />
                    </>
                )}
                sx={{ width: "70%" }}
                popupIcon={loading && <CircularProgress size={24} />}
                renderOption={(props, option) => (
                    <li
                        {...props}
                        onMouseEnter={() => handleOptionMouseEnter(option)}
                        onMouseLeave={handleOptionMouseLeave}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 4,
                            margin: 2,
                        }}
                    >
                        <div onClick={() => openCardDetails(option)}>
                            {hoveredOption === option && (
                                <img
                                    src={option.normal}
                                    alt={option.name}
                                    style={{
                                        position: "fixed",
                                        top: "50%",
                                        left: "-120px",
                                        transform: "translate(-50%, -50%)",
                                        width: "220px",
                                        zIndex: 9999,
                                    }}
                                />
                            )}
                            {getOptionLabel(option)}
                        </div>
                        {ownership && (
                            <IconButton
                                onClick={() => handleAddCardToDeck(option)}
                                size="small"
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        )}
                    </li>
                )}
            />
            {modalCard && (
                <CardPreviewDrawer
                    open={openModal}
                    onClose={handleModalClose}
                    deckId={deckId}
                    card={modalCard}
                    addCard
                    onAddCardComplete={onAddCardComplete}
                    ownership={ownership}
                />
            )}
            <SnackbarMessage
                open={showErrorSnackbar || showSuccessSnackbar}
                onClose={() => {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
                message={
                    showErrorSnackbar
                        ? "Error adding card"
                        : "Card added successfully"
                }
                severity={showErrorSnackbar ? "error" : "success"}
            />
        </>
    );
};

export default CardAutocomplete;
