import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { lightTheme, darkTheme } from "./themes/themes";
import Navbar from "./features/Navbar/Navbar";
import Home from "./features/Home/Home";
import UserDecks from "./features/UserDecks/UserDecks";
import Deck from "./features/SingleDeck/Deck";
import Profile from "./features/Profile/Profile";
import { AuthProvider } from "./context/AuthProvider";
import Collection from "./features/Collection/Collection";
import Settings from "./features/Settings/Settings";
import Explore from "./features/Explore/Explore";
import useUserSettingsStore from "./stores/userSettingsStore";
import CardView from "./features/CardView/CardView";
import FeedbackButton from "./components/FeedbackButton";
import Footer from "./components/Footer";
import Play from "./features/Play/Explore";

function App() {

    const { theme } = useUserSettingsStore();

    return (
        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
            <CssBaseline />
            <AuthProvider>
                <div className="app-container">
                    <Navbar />
                    <div className="content-container">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/user/:displayName" element={<Profile />} />
                            <Route path="/user/:displayName/decks" element={<UserDecks />} />
                            <Route path="/user/:displayName/collection" element={<Collection />} />
                            <Route path="/user/:displayName/settings" element={<Settings />} />
                            <Route path="/decks/:slug" element={<Deck />} />
                            <Route path="/explore" element={<Explore />} />
                            <Route path="/card/:uuid" element={<CardView />} />
                            <Route path="/play" element={<Play />} />
                        </Routes>
                    </div>
                    <FeedbackButton />
                    <Footer />
                </div>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
