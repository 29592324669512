import React, { useState, useEffect } from "react";
import ProfileIconDrawer from "./ProfileIconDrawer";
import useUserService from "../../hooks/useUserService";

// ensure image uploads are only .jpg, .png, and max uploads of images 2MB
const ProfileIcon = () => {
    const [profilePicture, setProfilePicture] = useState(null);
    const [openProfileIconDrawer, setOpenProfileIconDrawer] = useState(false);

    const {
        loadUserProfilePicture,
        errorUserProfilePicture,
        userProfilePicture,
    } = useUserService();

    useEffect(() => {
        if (
            !loadUserProfilePicture &&
            !errorUserProfilePicture &&
            userProfilePicture &&
            userProfilePicture.getProfilePicture
        ) {
            // If the query has completed successfully and the profile picture is retrieved
            setProfilePicture(
                `data:image/jpeg;base64, ${userProfilePicture.getProfilePicture}`
            );
        }
    }, [loadUserProfilePicture, errorUserProfilePicture, userProfilePicture]);

    const handleProfileIconDrawerClose = () => {
        setOpenProfileIconDrawer(false);
    };

    return (
        <>
            <img
                src={profilePicture}
                alt="Profile"
                style={{
                    border: "1px solid #d6d6d6",
                    borderRadius: "50%",
                    width: "50px",
                    transition: "transform 0.3s",
                    cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.1)";
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                }}
                onClick={() => setOpenProfileIconDrawer(true)}
            />
            <ProfileIconDrawer
                open={openProfileIconDrawer}
                onClose={handleProfileIconDrawerClose}
                profilePicture={profilePicture}
            />
        </>
    );
};

export default ProfileIcon;
