import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useAuthenticationService from "../../hooks/useAuthenticationService";
import SnackbarMessage from "../../components/SnackbarMessage";

const SignupModal = ({ open, onClose, onBackToLogin }) => {
    const [email, setEmail] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [validEmail, setValidEmail] = useState(true);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const { signup } = useAuthenticationService();

    const resetState = () => {
        setEmail("");
        setDisplayName("");
        setPassword("");
        setConfirmPassword("");
        setErrorMessage("");
        setSuccessMessage("");
        setPasswordsMatch(true);
        setValidEmail(true);
    };

    const validateEmail = (email) => {
        // Simple email format validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleSignup = async () => {
        // Validate email format
        if (!validateEmail(email)) {
            setValidEmail(false);
            return;
        }

        setPasswordsMatch(true);
        // Check if passwords match
        if (password !== confirmPassword) {
            setPasswordsMatch(false);
            return;
        }

        const result = await signup(email, displayName, password);

        if (result.success) {
            setSuccessMessage(result.message);
            setShowSuccessSnackbar(true);

            setTimeout(() => {
                resetState();
                onClose();
                onBackToLogin();
            }, 3000);
        } else {
            setShowErrorSnackbar(true);
            setErrorMessage(result.message);
        }
    };

    const handleBack = () => {
        onClose(); // Close the SignupModal
        onBackToLogin(); // Call the function to go back to LoginModal
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <form
                onSubmit={handleSignup}
                onKeyDown={(e) => e.key === "Enter" && handleSignup()}
            >
                <DialogTitle>Sign Up</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setValidEmail(true);
                        }}
                        helperText={!validEmail && "Invalid email"}
                        error={!validEmail}
                        required
                    />
                    <TextField
                        label="Display Name"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        required
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <TextField
                        label="Confirm Password"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        helperText={!passwordsMatch && "Passwords don't match"}
                        error={!passwordsMatch}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleBack} color="primary">
                        <ArrowBackIcon /> Back to Login
                    </Button>
                    <Button
                        onClick={handleSignup}
                        variant="contained"
                        color="primary"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </form>

            <SnackbarMessage
                open={showErrorSnackbar || showSuccessSnackbar}
                onClose={() => {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
                message={showErrorSnackbar ? errorMessage : successMessage}
                severity={showErrorSnackbar ? "error" : "success"}
            />
        </Dialog>
    );
};

export default SignupModal;
