import React from "react";
import SpellSheetsLogoLight from "../static/Spell-Sheets-logo-light-theme.png";
import { Button } from "@mui/material";

function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
    return (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
            <img
                src={SpellSheetsLogoLight}
                alt="logo-light"
                style={{ width: "350px" }}
            />
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <Button onClick={resetErrorBoundary} variant="outlined">
                Refresh Page
            </Button>
        </div>
    );
}

export default ErrorBoundaryFallback;
