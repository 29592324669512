import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Box,
    Menu,
    MenuItem,
    IconButton,
} from "@mui/material";
import { calculateTimeAgo } from "../../util/calculateTime.js";
import { renderColorSymbols } from "../../util/colorsUtils";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useDeckService from "../../hooks/useDeckService.jsx";
import SnackbarMessage from "../../components/SnackbarMessage.jsx";

const DeckList = ({ decks, setDeletionSuccess, editable }) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [anchorEl, setAnchorEl] = useState(null);
    const [deckIdToDelete, setDeckIdToDelete] = useState(null);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const createSortIndicator = () => null;

    // Sorting logic
    const sortedDecks = [...decks].sort((a, b) => {
        const aValue = a[orderBy];
        const bValue = b[orderBy];

        if (aValue === bValue) {
            return 0;
        }

        return order === "asc"
            ? aValue > bValue
                ? 1
                : -1
            : bValue > aValue
            ? 1
            : -1;
    });

    const handleMenuOpen = (event, deckId) => {
        setAnchorEl(event.currentTarget);
        setDeckIdToDelete(deckId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const { deleteDeck } = useDeckService();

    const handleDeleteDeck = async () => {
        deleteDeck(deckIdToDelete)
            .then(() => {
                // refetch decks in UserDecks
                setAnchorEl(null);
                setDeletionSuccess(true);
                setShowSuccessSnackbar(true);
            })
            .catch((err) => {
                console.log("err hit: ", err);
                setShowErrorSnackbar(true);
            });
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === "name"}
                                direction={orderBy === "name" ? order : "asc"}
                                onClick={() => handleSort("name")}
                            >
                                Name {createSortIndicator()}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === "colors"}
                                direction={orderBy === "colors" ? order : "asc"}
                                onClick={() => handleSort("colors")}
                            >
                                Colors {createSortIndicator()}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === "format"}
                                direction={orderBy === "format" ? order : "asc"}
                                onClick={() => handleSort("format")}
                            >
                                Format {createSortIndicator()}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === "lastUpdated"}
                                direction={
                                    orderBy === "lastUpdated" ? order : "asc"
                                }
                                onClick={() => handleSort("lastUpdated")}
                            >
                                Last Updated {createSortIndicator()}
                            </TableSortLabel>
                        </TableCell>
                        {/* empty column for menu icon column */}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* Map over sorted decks data and render rows */}
                    {sortedDecks.map((deck) => (
                        <TableRow key={deck.id}>
                            <TableCell>
                                <Link
                                    to={`/decks/${deck.slug}`}
                                    style={{
                                        color: "inherit",
                                    }}
                                >
                                    {deck.name}
                                </Link>
                            </TableCell>
                            <TableCell>
                                <Box display="flex">
                                    {renderColorSymbols(deck.colors)}
                                </Box>
                            </TableCell>
                            <TableCell>{deck.format}</TableCell>
                            <TableCell>
                                {calculateTimeAgo(deck.lastUpdated)}
                            </TableCell>
                            <TableCell style={{ width: 30 }}>
                                <IconButton
                                    onClick={(event) =>
                                        handleMenuOpen(event, deck.id)
                                    }
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    PaperProps={{
                                        elevation: 0, // Remove the shadow
                                        style: {
                                            border: "1px solid grey", // Add a gray border
                                        },
                                    }}
                                >
                                    {/* <MenuItem onClick={handleDeckSettings}>
                                        Settings
                                    </MenuItem>
                                    <Divider /> */}
                                    {editable ? (
                                        <MenuItem
                                            onClick={handleDeleteDeck}
                                            sx={{ color: "red" }}
                                        >
                                            Delete Deck
                                        </MenuItem>
                                    ) : (
                                        <></>
                                    )}
                                    {/* <MenuItem>Duplicate</MenuItem> */}
                                </Menu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <SnackbarMessage
                open={showErrorSnackbar || showSuccessSnackbar}
                onClose={() => {
                    setShowErrorSnackbar(false);
                    setShowSuccessSnackbar(false);
                }}
                message={
                    showErrorSnackbar
                        ? "Error Deleting deck"
                        : "Deck deleted successfully"
                }
                severity={showErrorSnackbar ? "error" : "success"}
            />
        </TableContainer>
    );
};

export default DeckList;
