import React from "react";
import { Typography, Box, Container } from "@mui/material";
import { getCurrentVersion } from "../util/versionControl";

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                color: (theme) => theme.palette.text.main,
                padding: (theme) => theme.spacing(2),
                textAlign: "left",
                bottom: 0,
                left: 0,
                width: "100%",
                zIndex: 10,
                // mt: 15,
            }}
        >
            <Container
                sx={{
                    alignItems: "left",
                    justifyContent: "left",
                }}
            >
                <Typography variant="body1" sx={{ fontSize: "12px", m: 1 }}>
                    Wizards of the Coast, Magic: The Gathering, and their logos
                    are trademarks of Wizards of the Coast LLC in the United
                    States and other countries. © 1993-2024 Wizards. All Rights
                    Reserved.
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "12px", m: 1 }}>
                    Spell Sheets is an independent entity and is not officially
                    associated with Wizards of the Coast LLC. However, Spell
                    Sheets may utilize trademarks and other intellectual
                    property owned by Wizards of the Coast LLC, in accordance
                    with Wizards' Fan Site Policy.
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "12px", m: 1 }}>
                    Version {getCurrentVersion()}
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
