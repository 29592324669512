import { gql } from "@apollo/client";

const authenticationQueries = {
    SIGN_UP_MUTATION: gql`
        mutation SignUp(
            $email: String!
            $displayName: String!
            $password: String!
        ) {
            signUp(
                registerDto: {
                    email: $email
                    displayName: $displayName
                    password: $password
                }
            )
        }
    `,

    LOGIN_MUTATION: gql`
        mutation Login($email: String!, $password: String!) {
            authenticate(loginDto: { email: $email, password: $password }) {
                accessToken
                tokenType
                displayName
                errorMessage
            }
        }
    `,

    VALIDATE_USER: gql`
        query ValidateUser {
            validateUser
        }
    `,
};

export default authenticationQueries;
