import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink, concat } from "@apollo/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";

const ApolloWrapper = () => {

    const accessToken = Cookies.get("accessToken"); // Retrieve the accessToken cookie value

    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                Authorization: accessToken ? `Bearer ${accessToken}` : "",
            },
        }));
        return forward(operation);
    });

    // Define your backend URLs based on environment variables
    // const graphqlUri = process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:8080/graphql';
    const devUri = "http://localhost:8080/graphql";
    const prodUri = "https://main-backend-ssl.spellsheets.com:443/graphql";

    const uri = process.env.NODE_ENV === 'production' ? prodUri : devUri;

    const httpLink = createHttpLink({
        uri: uri,
    });

    const client = new ApolloClient({
        link: concat(authLink, httpLink),
        cache: new InMemoryCache(),
    });

    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ApolloProvider>
    );
};

export default ApolloWrapper;
