import React, { useState } from "react";
import {
    Container,
    Typography,
    Card,
    Box,
    Divider,
    Alert,
    Grid,
} from "@mui/material";
import NewFeaturesModal from "../../components/NewFeaturesModal";

const Home = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Box sx={{ height: "90vh" }}>
            <NewFeaturesModal open={isModalOpen} onClose={handleCloseModal} />
            <Alert severity="info" sx={{ mb: 2 }}>
                Please note that this version of Spell Sheets is in early access
                and intended for testing purposes, with all data susceptible to
                potential loss.
            </Alert>
            <Typography variant="h4" align="center" sx={{ marginTop: 8 }}>
                Welcome to Spell Sheets!
            </Typography>
            <Typography variant="h6" align="center">
                Unleash the magic where deck tracking meets enchantment!
            </Typography>
            <Typography
                variant="body1"
                align="center"
                sx={{ marginBottom: 6, fontStyle: "italic" }}
            >
                (Continuous improvement ongoing. Share feedback via the feedback
                button.)
            </Typography>
            <Container sx={{ textAlign: "center" }}>
                {/* <Box
                    sx={{ display: "flex", width: "100%", textAlign: "center" }}
                > */}
                <Grid container>
                    <Grid item md={4} xs={12} sm={12} sx={{ p: 2 }}>
                        <Card
                            sx={{
                                width: "100%",
                                height: "250px",
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                p: 2,
                            }}
                        >
                            <Typography variant="h5">Create a Deck</Typography>
                            <Divider />
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                Begin your journey by creating an account and
                                dive into the art of deck building for Magic:
                                The Gathering. Craft strategic compositions for
                                any format, from Standard to Commander, and
                                unleash your creativity in the world of Magic.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item md={4} xs={12} sm={12} sx={{ p: 2 }}>
                        <Card
                            sx={{
                                width: "100%",
                                height: "250px",
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                p: 2,
                            }}
                        >
                            <Typography variant="h5">Search</Typography>
                            <Divider />
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                Explore Spell Sheets by searching for cards,
                                decks, and fellow users. Uncover the perfect
                                cards for your strategies, discover inspiring
                                decks, and connect with like-minded players—all
                                within the Spell Sheets app.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item md={4} xs={12} sm={12} sx={{ p: 2 }}>
                        <Card
                            sx={{
                                width: "100%",
                                height: "250px",
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                p: 2,
                            }}
                        >
                            <Typography variant="h5">Play Games </Typography>
                            <Divider />
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                (Coming Soon) Play Games. Engage in thrilling
                                matches with your custom-built decks, bringing
                                your strategies to life. Stay tuned to challenge
                                opponents and showcase your deck mastery on
                                Spell Sheets.
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>

                {/* </Box> */}
            </Container>
        </Box>
    );
};

export default Home;
