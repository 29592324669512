import React from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import cardhoarder from "../../static/storeIcons/cardhoarder.png";
import cardmarket from "../../static/storeIcons/cardmarket.png";
import edhrec from "../../static/storeIcons/edhrec.png";
import tcgplayer from "../../static/storeIcons/tcgplayer.png";

const CardPurchase = ({ card }) => {
    const storeIcons = {
        cardhoarder,
        cardmarket,
        tcgplayer,
        edhrec,
    };

    const renderStoreIcon = (store) => {
        return (
            <ListItemIcon>
                <img
                    src={storeIcons[store]}
                    alt={`${store} icon`}
                    style={{ width: 24, height: 24 }}
                />
            </ListItemIcon>
        );
    };

    const navigateToSite = (url) => {
        window.open(url, "_blank");
    };

    return (
        <>
            <List>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => navigateToSite(card.cardhoarder)}
                    >
                        {renderStoreIcon("cardhoarder")}
                        <ListItemText
                            primary={
                                <Typography variant="body1">
                                    Buy at <strong>Cardhoarder</strong>
                                </Typography>
                            }
                        />
                        <ListItemIcon>
                            <ArrowForwardIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => navigateToSite(card.cardmarket)}
                    >
                        {renderStoreIcon("cardmarket")}
                        <ListItemText
                            primary={
                                <Typography variant="body1">
                                    Buy at <strong>Cardmarket</strong>
                                </Typography>
                            }
                        />
                        <ListItemIcon>
                            <ArrowForwardIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => navigateToSite(card.tcgplayer)}
                    >
                        {renderStoreIcon("tcgplayer")}
                        <ListItemText
                            primary={
                                <Typography variant="body1">
                                    Buy at <strong>TCGPlayer</strong>
                                </Typography>
                            }
                        />
                        <ListItemIcon>
                            <ArrowForwardIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigateToSite(card.edhrec)}>
                        {renderStoreIcon("edhrec")}
                        <ListItemText
                            primary={
                                <Typography variant="body1">
                                    Buy at <strong>EDHREC</strong>
                                </Typography>
                            }
                        />
                        <ListItemIcon>
                            <ArrowForwardIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    );
};

export default CardPurchase;
