import React from "react";
import SadWizard from "../../static/sadWizard.png";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NoUserFound = ({ displayName }) => {
    const navigate = useNavigate();

    return (
        <div style={{ textAlign: "center" }}>
            <img
                src={SadWizard}
                style={{ width: "400px" }}
                alt="spellbook-icon"
            />
            <Typography variant="h5">
                No user found with name <b>"{displayName}"</b> on Spell Sheets
            </Typography>
            <Button
                variant="outlined"
                sx={{ m: 3 }}
                onClick={() => navigate("/")}
            >
                Go Home
            </Button>
        </div>
    );
};

export default NoUserFound;
