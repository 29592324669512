import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    IconButton,
    Divider,
    Menu,
    MenuItem,
    CircularProgress,
    Button,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import NoCardsSkeleton from "./NoCardsSkeleton";
import NoDeckFound from "./NoDeckFound";
import DeckTiles from "./DeckTiles";
import Hero from "./Hero";
import CardAutocomplete from "./CardAutocomplete";
import DeckStats from "./DeckStats";
// icons
import {
    PlayCircleOutline as PlayCircleOutlineIcon,
    EditOutlined as EditOutlinedIcon,
    LocalGroceryStoreOutlined as LocalGroceryStoreOutlinedIcon,
    MoreVert as MoreVertIcon,
    Settings as SettingsIcon,
} from "@mui/icons-material";
import useDeckService from "../../hooks/useDeckService";
import { transformDeckData, formatForExport } from "../../util/deckUtil";
import useDeckSettingsStore from "../../stores/deckSettingsStore";
import BuyDeckModal from "./BuyDeckModal";
import BulkEditModal from "./BulkEditModal";
import ExportModal from "./ExportModal";

const Deck = () => {
    const { slug } = useParams();

    const navigate = useNavigate();

    const { useDeckBySlug, useGetDeckOwnership, deleteDeck } = useDeckService();
    const { dataDeckBySlug, errorDeckBySlug, refetchDeckBySlug } =
        useDeckBySlug(slug);
    const { dataGetDeckOwnership } = useGetDeckOwnership(slug);
    const [deckData, setDeckData] = useState({});
    const [loadingPage, setLoadingPagePagePage] = useState(true);
    const [deckNotFound, setDeckNotFound] = useState(false);
    const [anchorElSettings, setAnchorElSettings] = useState(null);
    const [anchorElMore, setAnchorElMore] = useState(null);
    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);
    const [openBuyDeckModal, setOpenBuyDeckModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const [displayOwnershipText, setDisplayOwnershipText] =
        useDeckSettingsStore((state) => [
            state.displayOwnershipText,
            state.setDisplayOwnershipText,
        ]);

    const toggleOwnershipText = () => {
        setDisplayOwnershipText(!displayOwnershipText);
    };

    const handleMenuOpen = (event, name) => {
        switch (name) {
            case "Settings":
                setAnchorElSettings(event.currentTarget);
                break;
            case "More":
                setAnchorElMore(event.currentTarget);
                break;
            default:
                break;
        }
    };

    const handleMenuClose = (name) => {
        switch (name) {
            case "Settings":
                setAnchorElSettings(null);
            case "More":
                setAnchorElMore(null);
            default:
                break;
        }
    };

    const handleOpenModal = (name) => {
        switch (name) {
            case "BuyDeck":
                setOpenBuyDeckModal(true);
                break;
            case "BulkEdit":
                setOpenBulkEditModal(true);
                break;
            case "Export":
                setOpenExportModal(true);
                handleMenuClose("More");
                break;
            default:
                break;
        }
    };

    const handleCloseModal = (name) => {
        switch (name) {
            case "BuyDeck":
                setOpenBuyDeckModal(false);
                break;
            case "BulkEdit":
                setOpenBulkEditModal(false);
                break;
            case "Export":
                setOpenExportModal(false);
                break;
            default:
                break;
        }
    };

    const handleDeleteDeck = async () => {
        deleteDeck(deckData.id)
            .then(() => {
                navigate("/");
            })
            .catch((err) => {
                console.log("err hit: ", err);
            });
    };

    // grab deck information via slug
    useEffect(() => {
        refetchDeckBySlug();

        if (dataDeckBySlug) {
            const deckResult = dataDeckBySlug.getDeckBySlugName;
            if (deckResult) {
                const updatedDeckResult = {
                    ...deckResult,
                    tiles: transformDeckData(deckResult.deckCards),
                };
                setDeckData(updatedDeckResult);
            } else {
                setDeckNotFound(true);
            }
            setLoadingPagePagePage(false);
        }
    }, [refetchDeckBySlug, dataDeckBySlug]);

    const handleDeckMutationComplete = () => {
        refetchDeckBySlug();
    };

    const onDeckEditComplete = () => {
        refetchDeckBySlug();
    };

    return (
        <>
            {loadingPage ? (
                <Box sx={{ textAlign: "center", padding: "20px" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {deckNotFound ? (
                        <NoDeckFound slug={slug} />
                    ) : deckData ? (
                        <>
                            <Hero
                                deckData={deckData}
                                ownership={
                                    dataGetDeckOwnership?.getDeckOwnership
                                }
                                onDeckEditComplete={onDeckEditComplete}
                            />
                            <Box
                                id="deckSettings"
                                sx={{ margin: { xs: 0, md: 5 } }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="space-between"
                                >
                                    <Grid container item xs={12} md={4}>
                                        {/* TBD */}
                                        <Button
                                            color="primary"
                                            size="medium"
                                            startIcon={
                                                <PlayCircleOutlineIcon />
                                            }
                                            onClick={() => navigate("/play")}
                                        >
                                            Play
                                        </Button>
                                        {/* <Button
                                            color="primary"
                                            size="medium"
                                            startIcon={<EditOutlinedIcon />}
                                            onClick={() =>
                                                handleOpenModal("BulkEdit")
                                            }
                                        >
                                            Bulk Edit
                                        </Button> */}
                                        {/* <Button
                                            color="primary"
                                            size="medium"
                                            startIcon={
                                                <LocalGroceryStoreOutlinedIcon />
                                            }
                                            onClick={() =>
                                                handleOpenModal("BuyDeck")
                                            }
                                        >
                                            Buy Deck
                                        </Button> */}
                                        <Button
                                            color="primary"
                                            size="medium"
                                            startIcon={<MoreVertIcon />}
                                            onClick={(event) =>
                                                handleMenuOpen(event, "More")
                                            }
                                        >
                                            More
                                        </Button>
                                        <Menu
                                            id="more-menu"
                                            anchorEl={anchorElMore}
                                            open={Boolean(anchorElMore)}
                                            onClose={() =>
                                                handleMenuClose("More")
                                            }
                                        >
                                            <MenuItem
                                                sx={{
                                                    width: "200px",
                                                }}
                                                onClick={() =>
                                                    handleOpenModal("Export")
                                                }
                                            >
                                                Export
                                            </MenuItem>
                                            {/* Add more menu items as needed */}
                                        </Menu>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={3}
                                        justifyContent="flex-end"
                                    >
                                        <CardAutocomplete
                                            deckId={deckData.id}
                                            onAddCardComplete={
                                                handleDeckMutationComplete
                                            }
                                            ownership={
                                                dataGetDeckOwnership?.getDeckOwnership
                                            }
                                        />
                                        {dataGetDeckOwnership &&
                                            dataGetDeckOwnership?.getDeckOwnership && (
                                                <Box sx={{ ml: 1, mt: 1 }}>
                                                    <IconButton
                                                        aria-label="delete"
                                                        aria-controls="settings-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) =>
                                                            handleMenuOpen(
                                                                event,
                                                                "Settings"
                                                            )
                                                        }
                                                    >
                                                        <SettingsIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="settings-menu"
                                                        anchorEl={
                                                            anchorElSettings
                                                        }
                                                        open={Boolean(
                                                            anchorElSettings
                                                        )}
                                                        onClose={() =>
                                                            handleMenuClose(
                                                                "Settings"
                                                            )
                                                        }
                                                    >
                                                        <MenuItem
                                                            onClick={
                                                                toggleOwnershipText
                                                            }
                                                            sx={{
                                                                width: "200px",
                                                            }}
                                                        >
                                                            {displayOwnershipText
                                                                ? "Hide Ownership"
                                                                : "Show Ownership"}
                                                        </MenuItem>
                                                        <MenuItem
                                                            sx={{
                                                                color: "red",
                                                            }}
                                                            onClick={
                                                                handleDeleteDeck
                                                            }
                                                        >
                                                            Delete Deck
                                                        </MenuItem>
                                                        {/* Add more menu items as needed */}
                                                    </Menu>
                                                </Box>
                                            )}
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* loop through cards and display cards in respected tiles*/}
                            {deckData.deckCards &&
                            deckData.deckCards.length > 0 ? (
                                <DeckTiles
                                    deckData={deckData}
                                    onDeckUpdate={handleDeckMutationComplete}
                                    ownership={
                                        dataGetDeckOwnership?.getDeckOwnership
                                    }
                                />
                            ) : (
                                <NoCardsSkeleton />
                            )}
                            <Divider sx={{ m: 5 }} />
                            <DeckStats />
                        </>
                    ) : errorDeckBySlug ? (
                        <>
                            <h1>error</h1>
                        </>
                    ) : (
                        <>
                            <h1>loadingPage</h1>
                        </>
                    )}
                </>
            )}
            <BulkEditModal
                open={openBulkEditModal}
                onClose={() => handleCloseModal("BulkEdit")}
                cards={formatForExport(deckData?.tiles, 4)}
            />
            <ExportModal
                open={openExportModal}
                onClose={() => handleCloseModal("Export")}
                cards={deckData?.tiles}
            />
            <BuyDeckModal
                open={openBuyDeckModal}
                onClose={() => handleCloseModal("BuyDeck")}
            />
        </>
    );
};

export default Deck;
