import React, { useState } from "react";

import { Box, Chip, IconButton, Typography } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import TransparentCard from "../../components/TransparentCard";
import EditDeckDetailsDrawer from "./EditDeckDetailsDrawer";
import { calculateTimeAgo } from "../../util/calculateTime";

import default1 from "../../static/banners/land.jpg";
import default2 from "../../static/banners/cave.jpg";
import default3 from "../../static/banners/forestMountains.jpg";
import default4 from "../../static/banners/forestShrine.jpg";
import default5 from "../../static/banners/heaven.jpg";
import default6 from "../../static/banners/oceanVolcano.jpg";
import default7 from "../../static/banners/portTown.jpg";
import default8 from "../../static/banners/swamp.jpg";
import default9 from "../../static/banners/volcano.jpg";
import default10 from "../../static/banners/waterCity.jpg";

const bannerImages = {
    "/static/media/land.bd800c26a26f415d6668.jpg": default1,
    "/static/media/cave.0a6727d0c64cb2ad81ca.jpg": default2,
    "/static/media/forestMountains.bb986098c75ee37041bf.jpg": default3,
    "/static/media/forestShrine.36f1b3688b995478a047.jpg": default4,
    "/static/media/heaven.773b081a81377eb0a4f8.jpg": default5,
    "/static/media/oceanVolcano.b1cf0ce010c9a8606e0d.jpg": default6,
    "/static/media/portTown.48cee4dd371e1bbfcbcc.jpg": default7,
    "/static/media/swamp.95ee9a96cc2518d6587e.jpg": default8,
    "/static/media/volcano.d8ea5ea16d31bb4037f7.jpg": default9,
    "/static/media/waterCity.b9b27cf4562278ea09d2.jpg": default10,
};

const Hero = ({ deckData, ownership, onDeckEditComplete }) => {
    const [openDeckDetailEdit, setOpenDeckDetailEdit] = useState(false);

    const handleDeckDetailsDrawerClose = () => {
        setOpenDeckDetailEdit(false);
    };

    return (
        <>
            <Box
                id="hero"
                style={{
                    position: "relative",
                    overflow: "hidden",
                    height: "auto",
                }}
            >
                {/* image is going to change based on deck */}
                <img
                    src={bannerImages[deckData?.banner] || ""}
                    style={{
                        width: "100%",
                        height: "400px",
                        objectFit: "cover",
                    }}
                    alt="banner"
                />
                <TransparentCard
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        padding: ownership ? "15px" : "35px",
                        // padding: "15px",
                        maxWidth: "500px",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "20px",
                    }}
                >
                    {ownership && (
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <IconButton
                                onClick={() => setOpenDeckDetailEdit(true)}
                            >
                                <CreateIcon />
                            </IconButton>
                        </Box>
                    )}
                    <Typography variant="h3">{deckData?.name}</Typography>
                    <Typography variant="h5" sx={{ mt: 1 }}>
                        {deckData?.user?.displayName}
                    </Typography>
                    <Box>
                        {/* loop of deck format & tags */}
                        <Chip
                            label={deckData?.format}
                            // onDelete={}
                            size="small"
                            sx={{ mt: 1, mr: 1 }}
                        />
                        {deckData?.tags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                size="small"
                                sx={{ mt: 1, mr: 1 }}
                            />
                        ))}
                        {/* <Chip
                            // onClick={}
                            size="small"
                            label="+"
                            sx={{
                                fontSize: "15px",
                                mt: 1,
                            }}
                        /> */}
                    </Box>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        {deckData?.description}
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                        Last Updated: {calculateTimeAgo(deckData?.lastUpdated)}
                    </Typography>
                </TransparentCard>
            </Box>
            <EditDeckDetailsDrawer
                deckData={deckData}
                open={openDeckDetailEdit}
                onClose={handleDeckDetailsDrawerClose}
                onDeckEditComplete={onDeckEditComplete}
            />
        </>
    );
};

export default Hero;
