import { gql } from "@apollo/client";

const deckQueries = {
    GET_DECKS_BY_DISPLAYNAME: gql`
        query GetDecksByDisplayName($displayName: String) {
            getDecksByDisplayName(displayName: $displayName) {
                id
                slug
                name
                colors
                format
                privacy
                tags
                description
                lastUpdated
                # commenting these out since it slows down queries due to joining
                # user {
                #     id
                #     displayName
                # }
                # cards {
                #     id
                #     name
                #     normal
                # }
            }
        }
    `,

    GET_DECK_BY_SLUG: gql`
        query GetDeckBySlug($slug: String) {
            getDeckBySlugName(slug: $slug) {
                id
                slug
                name
                colors
                format
                privacy
                tags
                description
                lastUpdated
                banner
                user {
                    id
                    displayName
                }
                deckCards {
                    id
                    tile {
                        id
                        name
                        icon
                    }
                    card {
                        id
                        name
                        normal
                        typeLine
                        uuid
                        oracleText
                        flavorText
                        manaCost
                        rulingsUri
                        set
                        usd
                        alchemy
                        brawl
                        commander
                        duel
                        explorer
                        future
                        gladiator
                        historic
                        historicBrawl
                        legacy
                        modern
                        oathbreaker
                        oldSchool
                        pauper
                        pauperCommander
                        penny
                        pioneer
                        predh
                        premodern
                        standard
                        vintage
                        cardhoarder
                        cardmarket
                        tcgplayer
                        edhrec
                    }
                    own
                }
            }
        }
    `,

    ADD_DECK: gql`
        mutation AddDeck(
            $name: String!
            $format: String!
            $commander: String
            $privacy: String
            $deckImport: String
            $url: String
        ) {
            addDeck(
                deck: {
                    name: $name
                    format: $format
                    commander: $commander
                    privacy: $privacy
                    deckImport: $deckImport
                    url: $url
                }
            ) {
                id
                slug
                name
                user {
                    id
                    displayName
                    email
                }
            }
        }
    `,

    DELETE_DECK: gql`
        mutation DeleteDeck($deckId: ID!) {
            deleteDeck(deckId: $deckId)
        }
    `,

    ADD_CARD_TO_DECK: gql`
        mutation AddCardToDeck(
            $deckId: Int!
            $cardUid: String!
            $quantity: Int
            $own: Boolean
        ) {
            addCardToDeck(
                deckId: $deckId
                cardUid: $cardUid
                quantity: $quantity
                own: $own
            ) {
                id
                slug
                name
                colors
                format
                privacy
                tags
                description
                lastUpdated
                user {
                    id
                    displayName
                }
                deckCards {
                    id
                    tile {
                        id
                        name
                        icon
                    }
                    card {
                        id
                        name
                        normal
                        typeLine
                    }
                }
            }
        }
    `,

    REMOVE_CARD_FROM_DECK: gql`
        mutation RemoveCardFromDeck(
            $deckId: Int!
            $cardUid: String!
            $quantity: Int
        ) {
            removeCardFromDeck(
                deckId: $deckId
                cardUid: $cardUid
                quantity: $quantity
            ) {
                id
                slug
                name
                colors
                format
                privacy
                tags
                description
                lastUpdated
                user {
                    id
                    displayName
                }
                deckCards {
                    id
                    tile {
                        id
                        name
                        icon
                    }
                    card {
                        id
                        name
                        normal
                        typeLine
                    }
                }
            }
        }
    `,

    GET_DECK_OWNERSHIP: gql`
        query GetDeckOwnership($slug: String!) {
            getDeckOwnership(slug: $slug)
        }
    `,

    UPDATE_DECK_DETAILS: gql`
        mutation UpdateDeckDetails(
            $deckId: Int!
            $name: String
            $description: String
            $format: String
            $privacy: String
            $tags: [String]
        ) {
            updateDeckDetails(
                deckId: $deckId
                name: $name
                description: $description
                format: $format
                privacy: $privacy
                tags: $tags
            ) {
                id
                slug
                name
                colors
                format
                privacy
                tags
                description
                lastUpdated
                user {
                    id
                    displayName
                }
                deckCards {
                    id
                    tile {
                        id
                        name
                        icon
                    }
                    card {
                        id
                        name
                        normal
                        typeLine
                    }
                }
            }
        }
    `,

    UPDATE_DECK_CARD_OWNERSHIP: gql`
        mutation UpdateDeckCardOwnership(
            $deckId: Int!
            $cardUid: String!
            $quantity: Int
        ) {
            updateDeckCardOwnership(
                deckId: $deckId
                cardUid: $cardUid
                quantity: $quantity
            ) {
                id
                name
                deckCards {
                    id
                    card {
                        name
                        uuid
                    }
                    own
                }
            }
        }
    `,

    UPDATE_DECK_BANNER: gql`
        mutation UpdateDeckBanner($deckId: Int!, $banner: String!) {
            updateDeckBanner(deckId: $deckId, banner: $banner) {
                id
                name
                deckCards {
                    id
                    card {
                        name
                        uuid
                    }
                    own
                }
                banner
            }
        }
    `,

    UPDATE_CARD_TILE: gql`
        mutation UpdateCardTile(
            $deckId: Int!
            $cardUid: String!
            $tileName: String!
            $tileIcon: String
        ) {
            updateCardTile(
                deckId: $deckId
                cardUid: $cardUid
                tileName: $tileName
                tileIcon: $tileIcon
            ) {
                id
                name
                deckCards {
                    id
                    card {
                        name
                        uuid
                    }
                    own
                }
                banner
            }
        }
    `,
};

export default deckQueries;
